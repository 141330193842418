//Theme Vars
$theme-name: assistravel;
$hisa-color-brand: #fff;
$hisa-color-text: #03788b;
$color-subnav: #ccc;
$subnav-height: 0px;

$contact-info: "";
$contact-info-mobile: "";

.theme-#{$theme-name} {
  color: $color-darker-grey;

  //fix components for new header height
  @media only screen and (min-width: 650px) {
    .profile-holder,
    .register-holder,
    .messages-list-pannel {
      padding-top: calc(80px + #{$subnav-height});
    }

    .side-panel,
    .chat-wrap {
      top: calc(80px + #{$subnav-height});
    }

    .doctor-list {
      margin-top: calc(80px + #{$subnav-height});
    }
  }

  //Top Bar
  .top-bar {
    position: fixed;
    top: 0;
    background: $hisa-color-brand;
    color: $hisa-color-text !important;
    margin-top: $subnav-height;

    &:before {
      position: absolute;
      top: calc(#{$subnav-height} * -1);
      left: 0;
      width: 100%;
      height: $subnav-height;
      background: $color-subnav;
      content: "#{$contact-info}";

      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      white-space: pre;
      text-align: right;
      color: $hisa-color-text;
      line-height: $subnav-height;
      font-size: 0.8em;
      padding: 0;
      text-align: center;
      justify-content: center;
    }

    @media only screen and (max-width: 650px) {
      &.top-bar {
        margin-top: 0;

        &:before {
          display: none;
        }
      }
    }

    .header-title,
    .nav > li > a,
    .nav > li > span {
      color: $hisa-color-text;

      @media only screen and (max-width: 1024px) {
        color: $color-white;
      }
    }

    .nav {
      top: calc(80px + #{$subnav-height});

      &:before {
        display: none;
        width: 100%;
        height: $subnav-height;
        background: $color-subnav;
        content: "#{$contact-info-mobile}";

        justify-content: flex-end;
        align-content: center;
        align-items: center;
        white-space: pre;
        text-align: right;
        color: $hisa-color-text;
        line-height: $subnav-height;
        font-size: 0.8em;
        padding: 0;
        text-align: center;
        justify-content: center;
      }

      @media only screen and (max-width: 650px) {
        top: 80px;

        &:before {
          display: flex;
        }
      }
    }

    .main-brand {
      width: 240px;
      background-size: 135px;
      background-position: center center;
      background-image: url("../../assets/images/themes/#{$theme-name}/brand.png");
      @media only screen and (max-width: 650px) {
        background-size: 138px 45px;
      }
    }

    .header-title {
    }

    //hide phonenumber
    ul.nav {
      .help-line {
        display: none;
      }
    }

    .responsive-menu-icon {
      i {
        color: $hisa-color-text;
      }
    }
  }

  //Reg Box Logo
  .register-block-wrap .cs-wrap .rb-header .reg-logo {
    display: none;
  }
  .register-block-wrap .cs-wrap .rb-emergency-info-login {
    //display: none;
  }
  .register-block-wrap .cs-wrap {
    min-height: 240px;
  }

  //Hero
  .register-header {
    h3 {
      color: $hisa-color-text;
    }

    .jag-solid-btn {
      background-color: $hisa-color-text;

      &:hover {
        background-color: darken($hisa-color-text, 5%);
      }
    }

    &.company-signup {
      background-image: url("../../assets/images/themes/#{$theme-name}/header.jpg");
    }
    .jag_footer_wrap a {
      color: $hisa-color-text;
    }
  }

  //Modal
  .modal-window {
    z-index: 4;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 65);
    margin: 80px 0 0;
    height: auto;

    .modal {
      width: 100%;
      margin: 0 auto 0;
      border: none;
      pointer-events: all;
    }
  }
}
