.loader-animation {
  overflow: hidden;
  position: relative;
}

.loader-animation > div {
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

.loader-animation::before {
  background-color: #f5f6f7;
  background-image: url(../../assets/images/loader-bg.gif);
  background-repeat: repeat-y;
  background-size: 100% 1px;
  content: " ";
  display: block;
  height: 100%;
  border-radius: 3px;
}
