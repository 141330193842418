.site-header-centered {
  .wrap {
    position: relative;
  }

  .title-area {
    position: absolute;
    left: 0;

    a.lined {
      background-image: url(../../assets/images/business/logo-business.png);
      background-size: 145px;
      height: 50px;
    }
  }

  .nav-primary {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;

    .side-right {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
      }
    }

    .signup-link {
      margin-left: auto;

      span {
        min-width: 110px;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        background: $color-doc-brand;
        border-color: $color-doc-brand;
      }
    }

    .genesis-nav-menu {
      li,
      .popup-wrap.link {
        > a {
          color: $color-doc-brand;
          padding: 10px 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .site-header-centered {
    .title-area {
      position: relative;
      padding-bottom: 0;
    }

    .site-header-centered .nav-primary .genesis-nav-menu li > a,
    .site-header-centered .nav-primary .genesis-nav-menu li > .asLink {
      padding-top: 0;
    }

    .nav-primary {
      .side-right {
        position: relative;
        display: inline-block;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .site-header-centered {
    .title-area {
      position: absolute;
      top: 12px;
    }
  }
}
