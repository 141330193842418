.simple-landing-holder {
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
  padding: 60px 20px 20px;
  background-size: cover;
  background-position: center center;
  text-align: center;
  background-image: url(../../assets/images/home-hero-simple.jpg);

  @media screen and (min-width: 769px) {
    display: flex;
  }
}

.simple-view-cta {
  color: #fff;
  margin: auto;
  width: 100%;
  max-width: 420px;
  min-height: 0;
  padding: 50px 20px;
  .header-info {
    margin-bottom: 20px;
  }

  h1 {
    font-weight: 400;
    font-size: 65px;
    margin-bottom: 20px;
  }

  p.small {
    font-size: 0.7em;
    padding-bottom: 25px;
  }

  .btn {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    color: $color-white;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 5px;
    }

    &.sv-btn-alert {
      background-color: $color-sv-alert;

      &:hover {
        background-color: darken($color-sv-alert, 5%);
      }
    }

    &.sv-btn-info {
      background-color: $color-doc-brand-sec;

      &:hover {
        background-color: darken($color-doc-brand-sec, 5%);
      }
    }
    &.sv-btn-blue {
      background-color: $color-doc-brand;

      &:hover {
        background-color: darken($color-doc-brand, 5%);
      }
    }
  }

  .svp-btn-grey {
    background-color: $color-font;

    &:hover {
      background-color: darken($color-font, 5%);
    }
  }

  .svp-btn-alert {
    background-color: $color-sv-alert;

    &:hover {
      background-color: darken($color-sv-alert, 5%);
    }
  }

  .svp-btn-info {
    background-color: $color-doc-brand-sec;

    &:hover {
      background-color: darken($color-doc-brand-sec, 5%);
    }
  }

  .simple-view-panel {
    position: relative;
    margin-top: 40px;
    padding-bottom: 20px;

    .sv-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 22px;
      color: $color-white;
      opacity: 0.8;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }

    .svp-header {
      color: $color-white;
      padding: 50px 20px 70px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &.svp-header-alert {
        background-color: $color-sv-alert;
      }

      &.svp-header-info {
        background: rgb(34, 65, 118);
        background: linear-gradient(
          90deg,
          rgba(34, 65, 118, 1) 0%,
          rgba(26, 134, 161, 1) 100%
        );
      }

      h2 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        line-height: 1.2;
      }
    }

    .svp-body {
      position: relative;
      z-index: 1;
      padding: 20px 20px 30px;
      background-color: $color-white;
      color: $color-font;

      .svp-dr-info {
        margin-top: -65px;

        .dr-image {
          width: 90px;
          height: 90px;
          margin: 0 auto;
          border-radius: 50%;
          background-color: $color-main-bg;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
          background-position: center;
          background-size: cover;
        }

        p {
          font-size: 1.3em;
          margin-top: 10px;
        }
      }

      .svp-date-list {
        margin-top: 30px;
        padding: 0 20px;

        .svp-item {
          display: flex;
          align-items: center;
          text-align: left;
          padding: 10px 0;
          border-bottom: 1px solid $color-mid-grey;

          &:last-child {
            border-bottom: 0;
          }

          span {
            flex: 1;
            font-size: 0.875em;
          }

          .btn {
            width: auto;
            min-width: 72px;
            text-align: center;
            padding: 8px 12px;
            margin: 0;
            border-radius: 0;
            min-height: 0;
            line-height: 1;
            font-size: 0.65em;
            text-transform: uppercase;
          }
        }
      }
    }

    .svp-footer {
      button {
        width: 100%;
        padding: 12px;
        border-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        color: $color-white;
        text-transform: uppercase;
        font-size: 0.875em;
        font-weight: 600;
      }
    }
  }
}
