.ReactTable {
  @media (max-width: 760px) {
    .rt-thead.-header {
      display: none;
    }
    .rt-tr {
      display: flex;
      flex-direction: column;
      text-align: left;
      .rt-td {
        width: 95vw !important;
        &.name::before {
          content: "Name:";
          font-weight: 600;
          padding: 2px;
        }
        &.patient::before {
          content: "Patient:";
          font-weight: 600;
          padding: 2px;
        }
        &.doctor::before {
          content: "Doctor:";
          font-weight: 600;
          padding: 2px;
        }
        &.date::before {
          content: "Date:";
          font-weight: 600;
          padding: 2px;
        }
        &.email {
          text-transform: lowercase;
          &::before {
            content: "Email:";
            font-weight: 600;
            padding: 2px;
          }
        }
        &.phone::before {
          content: "Phone Number :";
          font-weight: 600;
          padding: 2px;
        }
        &.joined::before {
          content: "Joined Date :";
          font-weight: 600;
          padding: 2px;
        }
        &.room_url::before {
          content: "Room Id:";
          font-weight: 600;
          padding: 2px;
        }
        &.company::before {
          content: "Company :";
          font-weight: 600;
          padding: 2px;
        }
        &.firstEmergActivation::before {
          content: "Active Since :";
          font-weight: 600;
          padding: 2px;
        }
        &.status::before {
          content: "Status :";
          font-weight: 600;
          padding: 2px;
        }
        &.specialty::before {
          content: "Specialty :";
          font-weight: 600;
          padding: 2px;
        }
      }
    }
  }
}
// .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
//   background: rgba(96, 124, 138, 0.18);
// }
