$caret-width: 6px;
$dropdown-spacer: 10px;
$zindex-dropdown: 10;
$dropdown-min-width: 320px;
$dropdown-padding-y: 0.5rem;
$dropdown-item-padding-y: 10px;
$dropdown-item-padding-x: 20px;
$font-size-base: 1rem;
$body-color: #fff;
$dropdown-bg: #fff;
$dropdown-border-width: 1px;
$dropdown-border-color: #ddd;
$font-weight-normal: 400;
$dropdown-link-color: $color-font;
$dropdown-link-hover-color: #4a4a4a;
$dropdown-link-hover-bg: rgba(0, 0, 0, 0.08);
$dropdown-link-active-color: $color-doc-brand;
$dropdown-link-active-bg: rgba(0, 0, 0, 0.1);
$dropdown-link-disabled-color: #ccc;
$font-size-sm: 0.7em;
$dropdown-header-color: #ddd;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.125);

// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
  display: inline-block;
  position: relative;
}

button.dropdown-toggle {
  width: 100%;
  max-width: 100%;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    position: relative;
    &::after {
      border-top: 0;
      border-bottom: $caret-width solid;
    }
  }
}

button.dropdown-toggle.btn {
  height: 40px;
  background-color: #fff;
  padding: 0 10px;
  -webkit-transition: border-color;
  transition: border-color;
  border: 1px solid rgba(22, 23, 56, 0.22);
  border-radius: 5px;
  font-size: 1em;

  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: $caret-width * 0.85;
    vertical-align: $caret-width * 0.3;
    content: "";
    border-top: $caret-width solid $color-doc-brand;
    border-right: $caret-width solid transparent;
    border-left: $caret-width solid transparent;
  }

  &:empty::after {
    margin-left: 0;
  }

  &:focus,
  &:hover {
    border-color: $color-doc-brand;
  }

  &.btn-large {
    height: 50px;
    font-size: 1.2em;
    font-weight: 500;
    padding: 0 20px;
    border-radius: 6px;

    &::after {
      margin-left: 12px;
    }
  }

  &.btn-red {
    border-color: $color-danger;
    background-color: $color-danger;
    color: #fff;

    &:hover {
      border-color: darken($color-danger, 7.5%);
      background-color: darken($color-danger, 7.5%);
      color: #fff;
    }
    &::after {
      border-top-color: $color-white;
    }
  }
}

.dropdown-bot {
  button.dropdown-toggle {
    position: relative;
    min-width: 320px;
    height: 36px;
    -webkit-appearance: none !important;
    background-color: transparent;
    border: 1px solid $color-white;
    color: $color-white;
    font-size: 0.875em;
    padding: 0 45px 0 20px;
    margin: 10px 0;
    text-align-last: left;
    font-weight: 400;
    line-height: 37px;
    border-radius: 18px;

    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 8px;
      margin-top: -11px;
      width: 22px;
      height: 22px;
      border-radius: 12px;
      background-color: $color-white;
      background-image: url(../../assets/images/caret-green-down-lined.png);
      background-position: center 9px;
      background-repeat: no-repeat;
      background-size: 10px 6px;
      transform: rotate(-0deg);
    }
  }

  .dropdown-menu {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: 0;
    max-height: 200px;
    margin-bottom: 100px;

    a.dropdown-item {
      color: $color-white;
    }
  }
}

button.dropdown-toggle.lined {
  height: 48px;
  margin: 0;
  padding: 0 45px 0px 20px;
  background-color: transparent;
  border: 2px solid #c3cace;
  border-radius: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 500;

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background-image: url(../../assets/images/caret-white-down-lined.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 12px;
  }

  &:focus,
  &:hover {
    border-color: #fff;
  }
}

button.dropdown-toggle.naked {
  -webkit-appearance: none !important;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 2.4em;
  padding: 0 45px 0 0;
  margin: 10px;
  text-align-last: center;
  font-weight: 400;

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -8px;
    width: 20px;
    height: 20px;
    background-image: url(../../assets/images/caret-white-down-lined.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 12px;
  }
}

button.dropdown-toggle.rounded {
  position: relative;
  min-width: 320px;
  height: 36px;
  -webkit-appearance: none !important;
  background-color: $color-white;
  border: none;
  color: $color-font;
  font-size: 0.875em;
  padding: 0 45px 0 20px;
  margin: 10px 0;
  text-align-last: left;
  font-weight: 300;
  line-height: 37px;
  border-radius: 18px;
  font-size: 15px;

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -11px;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    background-color: $color-doc-brand-sec;
    background-image: url(../../assets/images/caret-white-down-lined.png);
    background-position: center 9px;
    background-repeat: no-repeat;
    background-size: 10px 6px;
    transform: rotate(-90deg);
  }
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: $zindex-dropdown;
  float: left;
  width: $dropdown-min-width;
  padding: 0;
  margin: $dropdown-spacer 0 0 -160px; // override default ul
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: none;
  border-radius: 1px;
  box-shadow: $box-shadow;
  max-height: 450px;
  overflow: hidden;
  overflow-y: auto;

  //for anim
  opacity: 0;
  pointer-events: none;
  perspective: 2000px;
  flex-grow: 1;
  transform: scale(0.95);
  transform-origin: 50% 0;
  opacity: 0;
  will-change: transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
a.dropdown-item {
  display: block;
  cursor: pointer;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: 500;
  font-size: 0.875em;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background: none; // For `<button>`s
  border: 0; // For `<button>`s
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
  }
}

// Open state for the dropdown
.show {
  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

.dropdown-menu.show {
  opacity: 1;
  transform: none;
  pointer-events: auto;
  -webkit-animation: select-menu-entry 0.25s;
  animation: select-menu-entry 0.25s;
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  margin-bottom: 0; // for use with heading elements
  font-size: $font-size-sm;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

@-webkit-keyframes select-menu-entry {
  from {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes select-menu-entry {
  from {
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
