@media only screen and (max-width: 1366px) {
}

@media screen and (max-width: 1200px) {
  .landing-page {
    .lndng-hero {
      background-image: url(../../assets/images/home-hero-doctor-02-mobile.jpg);
    }
  }
}

@media only screen and (max-width: 1150px) {
  .nav {
    font-size: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .top-bar {
    .main-brand {
      margin: 8px auto;
      float: none;
    }

    .header-title {
      display: none;
    }

    .responsive-menu-icon {
      display: block;
    }

    .nav {
      display: none;
      width: 100%;
      position: fixed;
      top: $top-bar-height;
      bottom: 0;
      overflow: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      &.d-block {
        display: block;
      }

      li {
        cursor: pointer;
        display: block;
        height: auto;
        float: none;
        border: none !important;

        &:hover {
          background: none;
        }

        a {
          border-bottom: 1px solid #444;
          line-height: 60px;
          height: 60px;
          background: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          color: #fff;

          &:hover {
            color: #fff;
            background: rgba(0, 0, 0, 0.85);
          }
        }

        > span {
          border-bottom: 1px solid #444;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #fff;

          &:hover {
            color: #fff;
            background: rgba(0, 0, 0, 0.8);
          }
        }

        &.settings-link {
          margin-left: 0;

          span:after {
            content: "Settings";
            margin-left: 10px;
          }
        }

        .drop-menu {
          display: block;
          position: relative;
          background: none;
          box-shadow: none;

          li {
            a {
              border-bottom: 1px solid #444;
              padding-left: 50px;
              border-radius: 0;

              &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 60px;
                text-align: center;
                color: #fff;
                font-family: "icomoon" !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 60px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e944";
              }
            }
          }
        }

        &.notification-action {
          display: none;
        }
      }
    }

    .mobile-notification-action {
      display: block;
    }
  }
}

@media screen and (max-width: 990px) {
  .landing-page {
    .lndng-hero {
      padding: 60px 0 60px;

      .home-primary-form {
        max-width: 400px;
        margin: 40px auto 0;
      }
    }

    .business-hero {
      .home-primary-form {
        max-width: 400px;
        margin: 40px auto 0;
      }
    }

    .form-container {
      h2 {
        text-align: center !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .login-popup,
  .block-popup {
    position: inherit;
    width: auto;
    margin: -54px auto 0;
    box-shadow: none;
    border-radius: 0;
  }

  .login-popup input,
  .block-popup input {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .hide-sm {
    display: none;
  }

  .side-panel {
    width: 100%;
    z-index: 999;

    &.minimized {
      height: 62px;
      border-bottom: 2px solid $color-border-grey;
    }
  }

  .call-nav {
    position: absolute;
    top: 60px;
    right: 60px;
    border-left: 2px solid $color-border-grey;
    border-bottom: 2px solid $color-border-grey;
  }

  .video-box-panel .vid-box {
    &.vid-full {
      display: block;
      width: 100%;

      .vid-controls {
        top: initial;
        right: initial;
        bottom: 0;
        left: 0;
        display: block;
      }
    }
  }

  .top-bar {
    .responsive-menu-icon {
      display: block;
    }

    .nav {
      display: none;
      width: 100%;
      &.d-block {
        display: block;
      }

      li {
        display: block;
        min-height: 60px;
        height: auto;
        float: none;
        border: none;

        &:hover {
          background: none;
        }

        a {
          border-bottom: 1px solid #444;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #fff;

          &:hover {
            color: #fff;
            background: rgba(0, 0, 0, 0.85);
          }
        }

        > span {
          border-bottom: 1px solid #444;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #fff;
        }

        .drop-menu {
          display: block;
          position: relative;
          background: none;
          box-shadow: none;

          li {
            a {
              border-bottom: 1px solid #444;
              padding-left: 50px;
              border-radius: 0;

              &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 60px;
                text-align: center;
                color: #fff;
                font-family: "icomoon" !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 60px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e944";
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .register-holder {
    padding-left: 20px;
    padding-right: 20px;

    .reg-body {
      width: 95%;
      min-width: 0;

      hr.divider {
        width: 100%;
      }

      .form-group {
        margin-bottom: 0;

        &.phone {
          display: flex;
          flex-direction: column;
          width: auto;
          justify-content: space-around;
          margin: auto;
          margin-bottom: 10px;
          position: relative;
          .PhoneInput {
            width: auto;
          }
        }
        &:after {
          visibility: hidden;
          display: block;
          font-size: 0;
          content: " ";
          clear: both;
          height: 0;
        }

        .Select--multi {
          width: 100%;
        }

        label,
        input,
        .password-input,
        select,
        textarea,
        .Select-control {
          display: block;
          width: 100% !important;
          text-align: left;
          box-sizing: border-box;
          clear: both;
        }

        label {
          margin-bottom: 5px;
        }

        .col-1-4,
        .col-3-4 {
          float: left;
          width: 25%;
          clear: none;
          margin: 0;
        }

        .col-3-4 {
          width: 73%;
          float: right;
        }

        .radio-wrap {
          margin: 0;
          display: block;
          text-align: left;

          input {
            display: inline-block;
          }
        }
      }

      .reg-actions {
        button {
          width: 40%;
          margin-bottom: 10px;
        }
      }

      .message {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .react-phone-number-input {
      width: 100% !important;

      .react-phone-number-input__country-select {
        width: 40px !important;
      }

      input[type="tel"] {
        width: auto;
      }
    }
  }

  .error-page {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-position: left bottom;

    .right-side {
      margin: 10px 0 0;

      &:before {
        height: 1px;
        width: 100%;
        left: 0;
        top: -5px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .landing-page {
    .lndng-hero {
      .home-primary-actions {
        h2 {
          font-size: 20px;
        }
      }
    }
  }

  .side-panel .chat-box {
    position: relative;
  }
  .side-panel .chat-box button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .register-holder {
    padding-top: 68px;
    padding-left: 5px;
    padding-right: 5px;
    .reg-body {
      .reg-actions {
        margin: 0;

        button {
          width: 100%;
          margin: 0 0 10px;
        }
      }
    }
  }

  .video-box-panel .enter-meeting {
    margin-left: 0;
    left: 10px;
    right: 10px;
    max-width: none;
    width: auto;
  }

  .tooltip {
    position: fixed;
    top: 75px;
    right: 50%;
    margin-right: -150px;

    &:before,
    &:after {
      display: none;
    }
  }

  .notification-banner {
    width: 100vw;
    height: calc(100vh - 80px);
  }
}

@media screen and (max-width: 321px) {
  .video-box-panel .vid-box {
    width: 320px;
  }
}

//grid fpor 5th Column
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

.faq-form {
  .form-group {
    display: flex;
    flex-direction: column;
    label {
      text-align: left !important;
      padding-bottom: 5px;
    }
    input {
      width: 100% !important;
    }
  }
}
.table-responsive.faq {
  .content-holder {
    width: 80%;
  }
  @media only screen and (max-width: 800px) {
    .content-holder {
      width: 80%;
    }
  }
}
.faq-inner {
  padding-bottom: 40px;
}
.faq-holder {
  display: flex;
  justify-content: space-around;

  .response {
    width: 90%;
    padding: 25px;
    background-color: #f5f5f5;
    border-radius: 6px;

    h3,
    h2 {
      color: #0f0f0f;
      font-weight: 500;
    }

    .btn {
      border-radius: 3px;
    }
  }

  .category-holder {
    width: 45%;
    padding: 25px;
    background-color: #f5f5f5;
    border-radius: 6px;

    h3,
    h2 {
      color: #0f0f0f;
      font-weight: 500;
    }

    ul {
      margin: 0px !important;
      margin-bottom: 25px;

      a {
        font-weight: 500;

        &:hover {
          color: #13bec4;
          text-decoration: underline;
        }
      }
    }

    li {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .btn {
      border-radius: 3px;
    }

    .btn-holder {
      display: flex;
      justify-content: center;
    }
  }
}

.form-group.faq-list {
  display: flex;
  margin: 30px auto 0;
  height: 45px;
  border-radius: 15px;

  input {
    height: 45px;
    margin-right: 10px;
    margin-bottom: 0;
    border-radius: 4px;
  }

  .btn-info:hover {
    background-color: darken($color: $color-doc-brand-sec, $amount: 5%);
    border-color: darken($color: $color-doc-brand-sec, $amount: 5%);
  }
}

.faq-holder.results {
  width: 100%;
  .category-holder {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .faq-holder {
    display: flex;
    flex-direction: column;
    margin: 0px;
    .category-holder {
      width: 100%;
      padding: 25px;
    }
  }
}
