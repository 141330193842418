.tooltip {
  display: none;
  position: absolute;
  width: 300px;
  height: auto;
  min-height: 50px;
  padding: 8px;
  border: 1px solid rgba($color-doc-brand, 0.15);
  background: $color-white;
  @include border-top-radius($border-radius);
  @include border-bottom-radius($border-radius);
  @extend .box-shad-main;
  color: $color-dark-grey;
  line-height: 30px;
  font-size: 0.8em;
  > * {
    margin-left: 5px;
    vertical-align: middle;
  }
  > :first-child {
    margin: 0;
  }
  .block {
    display: block;
    margin: 0 auto;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  [class^="btn-"] {
    min-width: 90px;
  }
  a.url {
    color: $color-mz-green;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .icon-spin {
    font-size: 30px;
    vertical-align: middle;
  }

  .request-type {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $color-font;
    color: $color-font;
    text-align: center;
    vertical-align: middle;

    i {
      display: block;
      line-height: 28px;
      font-size: 16px;
    }
  }
}

.top-right {
  top: 100%;
  margin-top: 12px;
  right: 5px;
  &:before {
    position: absolute;
    top: -12px;
    right: 5px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11.5px 12px 11.5px;
    border-color: transparent transparent rgba($color-doc-brand, 0.15)
      transparent;
  }
  &:after {
    position: absolute;
    top: -11px;
    right: 5px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11.5px 12px 11.5px;
    border-color: transparent transparent $color-white transparent;
  }
}

.center-left {
  top: 0;
  left: 100%;
  margin-left: 15px;
  &:before {
    position: absolute;
    top: 15px;
    left: -13px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11.5px 12px 11.5px 0;
    border-color: transparent rgba($color-doc-brand, 0.15) transparent
      transparent;
  }
  &:after {
    position: absolute;
    top: 15px;
    left: -11px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11.5px 12px 11.5px 0;
    border-color: transparent $color-white transparent transparent;
  }
  &.actions {
    span {
      cursor: pointer;
    }
  }
}

.tooltip {
  div.notification {
    position: relative;
    padding: 5px 0 5px 38px;
    border-bottom: 1px solid rgba($color-white, 0.2);
    text-align: left;

    .request-type {
      position: absolute;
      top: 8px;
      left: 0;
    }
    strong {
      display: block;
      line-height: 1.4;
      margin-bottom: 8px;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    button {
      margin-left: 5px;
      width: 80px;
      font-size: 16px;
    }
  }

  .share-row {
    border-bottom: 1px solid rgba($color-white, 0.2);
    padding: 10px 0;
    &:last-child {
      border-bottom: 0;
    }
    > * {
      margin-left: 5px;
      vertical-align: middle;
    }
  }
}

.alert {
  position: relative;
  min-height: 50px;
  background: $color-white;
  border-radius: 3px;
  padding: 22px 8px 22px 70px;
  text-align: left;
  font-size: 0.875em;
  color: $color-darker-grey;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

  p {
    line-height: 1.3;
    &:last-child {
      margin: 0;
    }
  }

  .icon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    background: $color-dark-grey;
    text-align: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    i {
      position: absolute;
      top: 50%;
      left: 0;
      width: 50px;
      margin-top: -13px;
      text-align: center;
      font-size: 24px;
      color: $color-white;
    }
  }

  &.alert-info {
    .icon-wrap {
      background: $color-doc-brand-sec;

      i {
        color: $color-white;
      }
    }
  }

  &.alert-success {
    .icon-wrap {
      background: $color-success;

      i {
        color: $color-white;
      }
    }
  }

  &.alert-danger {
    .icon-wrap {
      background: $color-danger;

      i {
        color: $color-white;
      }
    }
  }

  &.alert-warning {
    .icon-wrap {
      background: $color-warning;

      i {
        color: $color-white;
      }
    }
  }
}

//Notification Banner
.notification-banner {
  display: none;
  position: absolute;
  width: 300px;
  height: auto;
  top: 100%;
  margin-top: 0;
  right: 0;
  padding-top: 5px;
  background: $color-doc-brand;
  box-shadow: 0px 2px 2px 4px rgba(0, 0, 0, 0.05);

  &:before {
    position: absolute;
    top: -12px;
    right: 22px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 12px 8px;
    border-color: transparent transparent $color-notif-caret transparent;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 100%;
    height: 5px;
    background: $color-notif-caret;
  }

  .notification {
    background: $color-notif-bg;
    color: $color-white;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }

    .notification-body {
      display: flex;
      padding: 10px 15px;
      text-align: left;

      .request-type {
        padding-top: 4px;
        margin-right: 8px;

        i {
          font-size: 12px;
        }
      }

      span {
        font-weight: 300;
        line-height: 1.25;
        padding-top: 2px;
      }
    }

    .notification-footer {
      display: flex;
      justify-content: center;

      button {
        flex: 1;
        padding: 5px;
        text-transform: uppercase;
        font-weight: 300;
        color: $color-white;
        font-size: 12px;
      }

      .notif-accept {
        background: $color-doc-brand-sec;

        &:hover {
          background: darken($color-doc-brand-sec, 5%);
        }
      }

      .notif-reject {
        background: $color-notif-button;

        &:hover {
          background: darken($color-notif-button, 5%);
        }
      }
    }
  }
}
