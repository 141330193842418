.message-alert-box {
  text-align: center;
  padding: 15px;
  margin: 20px 20px 0;
  border-radius: 4px;
  line-height: 1.4;

  .justify-p {
    text-align: justify;
  }

  i {
    font-size: 50px;
  }

  h4 {
    text-transform: none;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }

  &.alert-grey {
    text-align: center;
    background: rgba($color-eclipse, 0.1);
    border: 1px solid $color-eclipse;

    h4,
    p,
    i {
      color: $color-eclipse;
    }
  }

  &.alert-info {
    text-align: center;
    background: rgba($color-doc-brand-sec, 0.1);
    border: 1px solid $color-doc-brand-sec;

    h4,
    p,
    i {
      color: $color-doc-brand-sec;
    }
  }

  &.alert-success {
    background: rgba($color-success, 0.1);
    border: 1px solid $color-success;
    color: $color-success;

    h4,
    p,
    i {
      color: $color-success;
    }
  }

  &.alert-danger {
    background: rgba($color-danger, 0.1);
    border: 1px solid $color-danger;
    color: $color-danger;

    h4,
    p,
    i {
      color: $color-danger;
    }
  }

  &.alert-warning {
    background: rgba($color-warning, 0.1);
    border: 1px solid $color-warning;
    color: $color-warning;

    h4,
    p,
    i {
      color: $color-warning;
    }
  }
}
