//Main

body {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $color-main-bg;
}
.precalltest {
  position: absolute;
  top: 0px;
  right: 0px;
}

.precalltest,
.precalltest .OT_subscriber,
.precalltest .OT_publisher {
  overflow: hidden;
}

.precalltest .OT_subscriber,
.precalltest .OT_publisher {
  float: left;
  width: 2px !important;
  height: 2px !important;
  min-width: 2px !important;
  max-width: 2px !important;
  min-height: 2px !important;
  max-height: 2px !important;
}

* {
  box-sizing: border-box;
}

small,
.small {
  font-size: 80%;
}

.hide {
  display: none !important;
}

.admin {
  height: 100%;
  overflow: auto;
}

.OT_publisher {
  width: 100px;
  height: 100px;
}
@media screen and (max-width: 650px) {
  .OT_publisher {
    width: 89px;
    height: 50px;
  }
}

.add-participant-modal {
  .array-fields {
    .array-group {
      .array-group {
        background: #f2f2f2;
        border-radius: 5px;
        margin: 10px 0px;
        padding: 0px 5px;
      }
      input {
        padding: 5px 2px !important;
        border-radius: 5px;
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 26px;
      }
      .remove-btn-holder {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button.btn-remove {
          background: transparent !important;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
    .btn-tertiary {
      background: transparent !important;
      font-size: 12px;
      text-decoration: underline;
      color: #10a6bd;
    }
  }
  button.teal {
    padding: 10px;
    background: #10a6bd;
    margin-bottom: 10px;
  }
}
