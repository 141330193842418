/*
  Modals
*/
.full-z-index {
  z-index: 999999999 !important;
}
.modal-window {
  z-index: 999999;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  .step-modal {
    .deleteInput {
      height: 40px;
      margin-bottom: 10px;
    }
  }
  .iconapp-style {
    height: 52px;
  }
  .input-center {
    text-align: center;
  }

  .charge-selection-actions {
    width: 100%;
    display: inline;
    line-height: 80px;
    .action {
      display: inline;
      width: 50%;
      padding: 5%;
    }
  }

  .medicare-tooltip {
    p {
      color: $color-white;
    }
  }

  .md-align-view-file {
    padding: 20px 0;
    text-align: center;
  }
  .md-align {
    padding: 80px 0;
  }

  .md-align-middle {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px 0 40px;

    .modal {
      margin-bottom: 40px;
    }
  }

  .modal {
    position: relative;
    width: auto;
    max-width: 550px;
    height: auto;
    margin: auto;
    padding: 60px 20px 20px;
    background: #fff;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .forgot {
      cursor: pointer;
      color: #272264;
      display: block;
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: right;
    }
  }

  .modal-view-file {
    max-width: 550px;
    height: calc(100vh - 50px);
    margin: auto;
    padding: 60px 20px 20px;
  }

  .modal-view-pdf {
    display: inline-block;
    height: auto;
    margin: 0 auto;
    padding: 0;

    .react-pdf__Page__canvas {
      width: 100% !important;
      max-width: 842px !important;
      height: auto !important;
    }
  }

  .modal-view-file,
  .modal-view-pdf {
    position: relative;
    width: 100%;
    max-width: 720px;
    min-height: 300px;
    background: #fff;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding-top: 0;

    .md-loader {
      width: 40px;
      margin: 80px auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    .page {
      width: 100% !important;
      border: none !important;
    }

    .md-header {
      text-align: left;
      position: relative;
    }
    .pdf-header {
      display: flex;
      padding: 10px 20px 10px 0px;

      input {
        border-radius: 4px;
        padding: 10px;
      }

      button {
        margin-left: 10px;
      }
    }

    .md-body {
      position: absolute;
      top: 30px;
      bottom: -30px;
      left: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
      }
    }
  }

  .md-header {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3px 25px 3px 10px;
    @include border-top-radius($border-radius);

    p {
      color: $color-white;
      margin: 0;
    }

    &.mh-sec {
      background: $color-doc-brand-sec;

      .md-close {
        color: $color-white;
      }
    }
  }

  h4 {
    color: $color-doc-brand-sec;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    color: $color-font;
  }

  .md-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    padding: 0;
    background: none !important;

    &:hover {
      text-decoration: none;
    }
  }

  .alert-modal {
    border-left: 10px solid;

    i.alert {
      display: inline-block;
      font-size: 40px;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.1em;
      line-height: 1.4;
    }

    &.danger {
      border-left-color: $color-danger;
      i,
      p {
        color: darken($color-danger, 10%);
      }
    }

    &.info {
      border-left-color: $color-info;
    }
  }

  .form-group {
    margin-bottom: 10px;
    position: relative;

    label,
    p strong {
      width: 120px;
      display: inline-block;
      text-align: right;
      margin-right: 5px;
      font-size: 0.9em;
      font-style: normal;
    }

    input,
    select,
    textarea,
    .Select-control {
      width: 360px;
      margin: 0;
      display: inline-block;
      font: inherit;
      border: none;
      outline: none;
      border-radius: 3px;
      height: 40px;
      background: rgba($color-doc-brand, 0.04);
      border: 1px solid rgba($color-doc-brand, 0.15);
      padding: 0 10px;
      &.pop-up {
        width: "auto";
      }

      &:focus {
        border: 1px solid rgba($color-doc-brand, 0.35);
      }

      &[type="checkbox"] {
        width: auto;
        height: auto;
      }
    }

    p {
      margin: 0 0 5px;

      em {
        margin-left: 5px;
      }
    }

    textarea {
      padding: 10px;
      vertical-align: text-top;
      resize: none;
      height: 80px;
    }

    &:after {
      display: none;
      content: attr(data-alert);
      width: 100%;
      margin: 3px 0 0;
      padding: 6px 10px;

      background: rgba($color-danger, 0.15);

      font-size: 0.75em;
      text-align: left;
      color: $color-danger;

      @include border-top-radius($border-radius);
      @include border-bottom-radius($border-radius);
    }

    &.alert-error {
      &:after {
        display: inline-block;
      }

      input {
        border: 1px solid $color-danger;
      }
    }

    &.centered {
      label {
        width: auto;
      }
    }

    &.full-width {
      label,
      input,
      select,
      textarea,
      .Select-control {
        width: 100%;
        text-align: left;
      }
      p strong,
      p em {
        display: inline-block;
        width: auto;
      }
    }
  }

  .md-footer {
    margin: 20px auto 0;
    max-width: 475px;

    &.txt-right {
      text-align: right;
    }

    p {
      font-size: 80%;
      margin: 0;
    }

    .btn {
      cursor: pointer;
      width: auto;
      font-size: 14px;
      margin: 0 2px 6px;

      i {
        font-size: 25px;
        margin-right: 3px;

        &.icon-phone-wave {
          font-size: 20px;
        }
      }
    }

    a {
      margin-left: 5px;
    }
  }

  .small-text {
    font-size: small;
  }
}

.modal-window {
  .request-app-modal {
    width: 270px;
    padding: 25px;
    text-align: center;

    .or-separ {
      position: relative;
      border-top: 1px solid #ccc;
      margin: 25px 0;
      height: 1px;

      span {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        background: #f2f2f2;
        top: -10px;
      }
    }

    p {
      margin: 15px 0;
    }
  }
}

.md-sign-up,
.app-custom-form {
  &.consult-dr {
    .modal {
      margin-top: 50px;
      max-width: 380px;
    }
  }
  .appointment-form {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px !important;
  }
  .modal,
  .appointment-form {
    margin: 100px auto;
    max-width: 380px;

    .md-header {
      .md-close {
        color: #525252;

        &:hover {
          color: #000;
        }
      }

      h1,
      h3,
      h4 {
        margin-top: 30px;
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        color: $color-darker-grey;
      }

      h3 {
        color: $color-doc-brand-sec;
        font-size: 26px;
      }
    }

    .md-body {
      padding: 20px 0 0;

      p {
        font-size: 0.9em;
        line-height: 1.3;
        text-align: center;
        margin: 15px 0 35px;
        color: $color-darker-grey;
      }

      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      input[type="tel"],
      textarea {
        border-radius: 5px;
        line-height: 40px;
        padding: 0 10px;
        font-size: 1em;
        margin-bottom: 15px;
      }

      textarea {
        height: 100px;
        resize: none;
        font-size: 0.9em;

        &.rate-message {
          background: rgba(2, 2, 2, 0.03);
          margin-top: 10px;
          border-radius: 4px;
          border-bottom: none;
        }
      }
    }

    .forgot {
      margin-top: -15px;
    }

    .md-footer {
      margin: 0;
      text-align: center;
    }

    .btn {
      line-height: 40px;
      width: 100%;
    }

    .btn-info {
      padding: 0 15px;
      line-height: 40px;
      display: block;
      background: $color-doc-brand-sec;
      border-color: $color-doc-brand-sec;
      text-transform: none;
    }
    .btn-warning {
      padding: 0 15px;
      line-height: 40px;
      display: block;
      background: $color-mz-yellow;
      border-color: $color-mz-yellow;
      text-transform: none;
    }

    .btn-primary {
      padding: 0 15px;
      line-height: 40px;
      background-color: #272264;
      border-color: #272264;
      color: #fff;
      &:hover {
        background-color: #18153e;
        border-color: #18153e;
        color: #fff;
      }
    }

    .btn-success {
      padding: 0 15px;
      line-height: 40px;
      background-color: $color-success;
      border-color: $color-success;
      color: #fff;
      &:hover {
        background-color: $color-success;
        border-color: $color-success;
        color: #fff;
      }
    }

    .btn-secondary {
      background-color: #1b75bb;
      border-color: #1b75bb;
      &:hover {
        background-color: #2990e0;
        border-color: #2990e0;
      }
    }

    .btn-tertiary {
      background-color: #13bec4;
      border-color: #13bec4;
      &:hover {
        background-color: #0e9196;
        border-color: #0e9196;
      }
    }

    .small-txt {
      font-size: 0.9em;
    }

    a {
      display: inline-block;
      font-weight: 400;
      margin: 20px 0 0;
      cursor: pointer;
    }

    .link:hover {
      color: $color-doc-brand-sec;
      text-decoration: underline;
    }

    .tool-tip {
      display: inline-block;
      vertical-align: bottom;
      color: $color-doc-brand-sec;
      margin-left: 5px;
    }
  }
}

.md-confirm {
  .modal {
    margin-top: 80px;
    max-width: 380px;
    border-radius: 8px;
    border: none;

    .md-header {
      .md-close {
        color: inherit;
        top: 12px;
        right: 12px;
        font-size: 22px;
        color: #646464;
      }
    }

    .md-body {
      padding: 20px 0;
      text-align: center;

      p {
        font-size: 1.2em;
        line-height: 1.3;
        text-align: center;
        margin: 15px 0;
        color: $color-darker-grey;
      }

      btn {
        margin: 0 auto;
        padding: 0 20px;
      }
      .icon-warning3 {
        color: red;
        font-size: 60px;
        margin-bottom: 10px;
      }
    }
    .md-footer {
      display: flex;
      justify-content: center;
      a {
        padding: 0 15px;
        line-height: 40px;
        color: #fff;
        &.as-link {
          color: #272264;
        }
        &.btn-primary {
          background-color: #272264 !important;
          border-color: #272264;
          &:hover {
            background-color: #18153e !important;
            border-color: #18153e;
            color: #fff;
          }
        }
      }
    }
  }
}

.md-medicare {
  .modal {
    max-width: 380px;
    margin-top: 80px;
    min-height: 240px;
    padding-top: 0;

    .md-header {
      .md-close {
        color: inherit;
      }

      h4 {
        margin-top: 30px;
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        color: $color-darker-grey;
      }
    }

    .md-body {
      padding: 30px 0 0;

      strong {
        font-weight: 700;
        line-height: 1.3;

        &.color-blue {
          display: block;
          color: $color-doc-brand;
          text-align: center;
        }
      }

      ol {
        margin: 20px 0;
        padding: 0 0 0 20px;

        li {
          margin-bottom: 5px;

          a {
            color: $color-doc-brand-sec;
          }
        }
      }
    }

    .md-footer {
      margin-top: 30px;
      text-align: center;

      .btn {
        display: inline-block;
        min-width: 200px;
      }
    }
  }
}

.modal-window {
  .special-modal {
    color: $color-darker-grey;
    border: none;
    padding: 0;
    width: 100%;
    max-width: 405px;
    margin: 0 20px;
    border-radius: 6px;

    .md-header {
      position: relative;
      background: $color-doc-brand-sec;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      justify-content: flex-start;
      padding: 0;
      overflow: hidden;

      .mdh-close {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 50px;
        height: 50px;
        color: #fff;
        line-height: 54px;
        text-align: center;
        font-size: 24px;

        &:hover {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.75);
        }
      }

      .mdh-link {
        flex: 1;
        padding: 12px 10px 8px;
        border-bottom: 4px solid transparent;
        line-height: 1;
        text-align: center;
        color: rgba(0, 0, 0, 0.25);
        font-size: 26px;
        font-weight: 500;
        text-transform: uppercase;

        &:last-child {
          padding-right: 50px;
        }

        &.active,
        &:hover {
          text-decoration: none;
          color: $color-white;
          border-bottom-color: rgba(0, 0, 0, 0.25);
        }
      }

      .md-header-tittle {
        display: block;
        width: 100%;
        min-height: 45px;
        padding: 18px 40px 13px;
        border-bottom: 4px solid rgba(0, 0, 0, 0.25);
        line-height: 1;
        color: $color-white;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .md-body {
      padding: 30px 40px 15px;

      p {
        text-align: left;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.2;
        margin-bottom: 12px;
      }
    }

    .md-sub-header {
      position: relative;
      padding: 15px 10px;
      background: rgba(0, 0, 0, 0.035);
      border-bottom: 1px solid rgba(0, 0, 0, 0.075);

      &:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
      }

      span {
        text-align: center;
        display: block;
        color: $color-eclipse;
        font-size: 16px;
        line-height: 1.1;
        margin-bottom: 5px;

        &.small {
          font-size: 12px;
          margin: 0;
        }
      }
    }
    .md-footer {
      display: flex;
      justify-content: space-between;
      padding: 0 40px 30px;
      margin: 0;
      max-width: none;

      a {
        font-weight: 500;
        flex: 0 0 auto;
        min-width: 46%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 8px 10px;
        text-transform: uppercase;
        line-height: 1.4;
        font-size: 0.875em;

        &.btn-lined {
          color: $color-doc-brand;
          border: 1px solid $color-doc-brand;
          background-color: transparent;

          &:hover {
            text-decoration: none;
            color: $color-white;
            border: 1px solid $color-doc-brand;
            background-color: $color-doc-brand;
          }
        }

        &.btn-solid {
          color: #fff;
          border: 1px solid $color-doc-brand;
          background: $color-doc-brand;

          &:hover {
            text-decoration: none;
            background-color: darken($color-doc-brand, 10%);
          }
        }
      }
    }
  }
}

.md-alert {
  padding: 14px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  background: darken($color-light-grey, 5%);

  &--danger {
    background: $color-danger;
    color: $color-white;
  }

  &--warning {
    background: $color-warning;
    color: $color-white;
  }

  &--info {
    background: $color-info;
    color: $color-white;
  }

  &--success {
    background: $color-success;
    color: $color-white;
  }
}

.login-stand-alone {
  min-height: 100vh;
  background-color: #f5f5f5;

  .modal-window {
    position: relative;
    background-color: transparent;
    padding-bottom: 20px;

    .modal {
      box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 5%);

      .md-close {
        display: none;
      }
    }
  }

  .login-stand-alone-brand {
    width: 105px;
    height: 32px;
    margin: auto auto 0;
    background-size: contain;
    background-position: center bottom;
    color: $color-white;
    text-align: center;
    background-image: url(../../assets/images/login-stand-alone-brand.png);
    background-repeat: no-repeat;
  }
}
.modal-window.form {
  .modal {
    max-width: 400px;
    margin-top: 40px;
    input {
      height: 40px;
      font-size: 14px;
    }
    label {
      text-align: left;
    }
  }
}
