//Height
.h10 {
  height: 10px !important;
}
.h20 {
  height: 20px !important;
}
.h30 {
  height: 30px !important;
}
.h40 {
  height: 40px !important;
}
.h50 {
  height: 50px !important;
}

.h100 {
  height: 100px !important;
}
.h150 {
  height: 150px !important;
}

.h200 {
  height: 200px !important;
}
.h250 {
  height: 250px !important;
}

/*    Widths & Heights
+-------------------------------------------------------------------------------------------------+
+-------------------------------------------------------------------------------------------------+
                                                                                                */

.w1p {
  width: 1% !important;
}
.w5p {
  width: 5% !important;
}
.w10p {
  width: 10% !important;
}
.w15p {
  width: 15% !important;
}
.w20p {
  width: 20% !important;
}
.w25p {
  width: 25% !important;
}
.w30p {
  width: 30% !important;
}
.w35p {
  width: 35% !important;
}
.w40p {
  width: 40% !important;
}
.w45p {
  width: 45% !important;
}
.w50p {
  width: 50% !important;
}
.w55p {
  width: 55% !important;
}
.w98p {
  width: 98% !important;
}
.w99p {
  width: 98% !important;
}
.w100p {
  width: 100% !important;
}
.w350px {
  width: 500px !important;
}
.w500px {
  width: 500px !important;
}

@media only screen and (max-width: 580px) {
  .sm-h100 {
    height: 100px !important;
  }
  .sm-h0 {
    height: 0px !important;
  }
  .sm-h150 {
    height: 150px !important;
  }
  .sm-h250 {
    height: 250px !important;
  }
}

@media only screen and (max-width: 768px) {
  .md-h250 {
    height: 250px !important;
  }
}
.ml-auto {
  margin-left: auto !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.ml-100 {
  margin-left: 100px !important;
}

.mr-5 {
  margin-left: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mr-90 {
  margin-right: 90px !important;
}

.mr-100 {
  margin-right: 100px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-100 {
  margin-top: 100px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}

.no-wrap {
  white-space: nowrap;
}

//Text alignment
.txt-center {
  text-align: center;
}
.txt-justify {
  text-align: justify !important;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left !important;
}

//Text decoration
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

.d-flex {
  display: flex;
}
// Align Items
.items-stretch {
  align-items: stretch;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}

// Justify Content
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
// Flex Direction
.flex-col {
  flex-direction: column;
}

//text color
.txt-color {
  &-back {
    color: $color-mz-dark;
  }
  &-green {
    color: $color-mz-green;
  }
  &-red {
    color: $color-mz-red;
  }
  &-blue {
    color: $color-mz-blue;
  }
  &-navy {
    color: $color-doc-brand !important;
  }
  &-yellow {
    color: $color-mz-yellow;
  }
  &-grey {
    color: $color-mz-grey;
  }
  &-white {
    color: white !important;
  }
}
.txt-underline {
  text-decoration: underline;
}
.txt-size {
  &-xs {
    font-size: 0.5rem;
  }
  &-sm {
    font-size: 0.75rem;
  }
  &-md {
    font-size: 1rem;
    line-height: 1rem;
  }
  &-lg {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  &-xl {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  &-xxl {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
