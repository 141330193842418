.invite-modal-wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-image: url("../../assets/images/home-footer-hero.jpg");
}

.mpi-patient-invite {
  color: $color-darker-grey;

  .modal {
    padding: 0;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 380px;
    border: none;
    border-radius: 8px;
    background: $color-white;
    color: $color-font-grey;

    .md-header {
      position: relative;
      min-height: 22px;
      padding: 20px 10px 5px;

      .md-close {
        top: 0;
        right: 0;
        color: inherit;
        font-size: 22px;
      }

      .md-back {
        position: absolute;
        top: 2px;
        left: 0;
        color: inherit;
        font-size: 18px;

        &:hover {
          text-decoration: none;
        }
      }

      .md-header-link {
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: lighten($color-doc-brand, 10%);
        font-weight: 500;
        font-size: 12px;
        text-decoration: underline;
      }
      .md-header-h1 {
        display: block;
        margin-top: 15px;
        text-align: center;
        text-transform: uppercase;
        color: $color-doc-brand;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .md-body {
      padding: 20px;

      .mpi-image {
        background-size: cover;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        margin: 20px auto;
      }

      .mpi-image-desc {
        font-size: 0.7em;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;

        span {
          font-weight: 300;
        }
      }

      .mpi-header {
        margin-top: 30px;
        margin-bottom: 30px;

        h4 {
          color: $color-darker-grey;
          text-align: center;
          font-size: 2.2em;
          font-weight: 400;
          margin-bottom: 5px;
        }

        p {
          text-align: center;
          font-size: 1.2em;
          font-weight: 300;
        }
      }

      .mpi-form {
        input[type="text"],
        input[type="email"],
        input[type="password"],
        .mpi-create-password {
          width: 100%;
          margin-bottom: 15px;
          border: none;
          height: 45px;
          line-height: 45px;
          background: #ebebeb;
          border: 1px solid #ebebeb;
          text-align: left;
          padding: 0 15px;
          font-size: 1em;
          font-weight: 400;
          color: #505050;

          &:focus {
            outline: none;
          }
        }

        input[type="text"],
        input[type="email"],
        input[type="password"] {
          &:focus {
            outline: none;
            border: 1px solid $color-doc-brand-sec;
          }
        }

        input[type="password"].mpi-create-password,
        .mpi-create-password {
          background: $color-dark-grey;
          color: $color-white;
        }

        button.mpi-create-password {
          cursor: pointer;
        }

        .mpi-create-password::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $color-white;
        }

        .mpi-create-password::-moz-placeholder {
          /* Firefox 19+ */
          color: $color-white;
        }

        .mpi-create-password:-ms-input-placeholder {
          /* IE 10+ */
          color: $color-white;
        }

        .mpi-create-password:-moz-placeholder {
          /* Firefox 18- */
          color: $color-white;
        }
      }
    }

    .mpi-submit {
      width: 100%;
      height: 45px;
      padding: 0 15px;
      border: none;
      background: $color-doc-brand-sec;
      color: $color-white;
      text-transform: uppercase;
      line-height: 45px;
      font-size: 1em;
      font-weight: 400;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: $color-doc-brand-sec;
      cursor: pointer;

      &:hover {
        background: darken($color-doc-brand-sec, 5%);
      }
    }

    .md-footer-message {
      text-align: center;
      font-size: 12px;
      padding-bottom: 10px;

      span {
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
      }

      a {
        font-weight: 500;
        color: $color-doc-brand-sec;

        &:hover {
          color: $color-doc-brand-sec;
          text-decoration: underline;
        }
      }
    }
  }
}
