//Theme Vars
$theme-name: hemisphere;
$hisa-color-brand: #000;
$hisa-color-text: #fff;

.theme-#{$theme-name} {
  color: $color-darker-grey;

  //Top Bar
  .top-bar {
    position: fixed;
    top: 0;
    background: $hisa-color-brand;
    color: $hisa-color-text !important;

    .header-title,
    .nav > li > a,
    .nav > li > span {
      color: $hisa-color-text;
    }

    .main-brand {
      width: 150px;
      background-size: 115px 55px;
      background-image: url("../../assets/images/themes/#{$theme-name}/brand.png");
    }
  }

  .responsive-menu-icon i {
    color: $hisa-color-text;
  }

  //Reg Box Logo
  .register-block-wrap .cs-wrap .rb-header .reg-logo {
    display: none;
  }

  //Hero
  .register-header {
    &.company-signup {
      background-image: url("../../assets/images/themes/#{$theme-name}/header.jpg");
    }
  }

  //Modal
  .modal-window {
    z-index: 4;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    margin: 80px 0 0;
    height: auto;

    .modal {
      width: 100%;
      margin: 0 auto 0;
      border: none;
      pointer-events: all;
    }
  }

  .btn-holder button,
  button.teal,
  .booking-calendar .react-datepicker__day--selected {
    background: #152a41 !important;
  }
}
