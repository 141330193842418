.admin-holder {
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}

.doctor-list {
  margin-top: $top-bar-height;
  padding: 20px;

  .top-actions {
    text-align: right;
    margin-bottom: 10px;
  }

  .final-col {
    min-width: 130px;
  }

  .no-results {
    text-align: center;
    color: #262262;
    display: inline-block;
    vertical-align: middle;
    padding: 1em;
    width: 100%;

    i {
      font-size: 70px;
      display: inline-block;
      vertical-align: middle;
    }

    p {
      font-size: 1.25em;
      line-height: 1.3;
      text-transform: uppercase;
      margin-top: 15px;
    }
  }

  input[type="text"] {
    font: inherit;
    width: 140px;
    outline: none;
    border-radius: 3px;
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid rgba($color-doc-brand, 0.35);
    }
  }

  table.styled {
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    font-size: 0.825em;

    > thead {
      tr {
        background: $color-dark-grey;
        color: #fff;

        td {
          vertical-align: middle;
          padding: 10px;

          a.caret {
            float: right;
            width: 0;
            height: 0;
            display: inline-block;
            border-style: solid;
            margin-top: 2px;

            &.up {
              border-width: 0 5px 10px 5px;
              border-color: transparent transparent $color-white transparent;

              &.active {
                border-color: transparent transparent $color-light-blue
                  transparent;
              }
            }

            &.down {
              border-width: 10px 5px 0 5px;
              border-color: $color-white transparent transparent transparent;

              &.active {
                border-color: $color-light-blue transparent transparent
                  transparent;
              }
            }
          }
        }
      }

      small {
        display: block;
        font-style: italic;
        font-size: 90%;
        margin-top: 4px;
      }
    }

    > tbody {
      tr {
        &:nth-child(odd) {
          background: rgba(255, 255, 255, 0.5);
        }

        td {
          vertical-align: top;
          padding: 10px;
        }
      }
    }

    .col-date-time {
      width: 140px;
    }

    select {
      background: transparent;
      -webkit-appearance: none;
      border: none;
      line-height: 1.4 !important;
      padding-right: 22px;
      font: inherit;
      background-image: url("../../assets/images/caret-down.png");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 14px 7px;
      color: $color-doc-brand;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.no-margin-top {
  margin-top: 0px;
}

.btn-table {
  margin-bottom: 5px;
  width: 75px;
  text-align: center;

  span {
    line-height: inherit;
  }

  &:last-child {
    margin: 0;
  }
}

.mw-300p {
  max-width: 300px !important;
}

.table-responsive {
  @media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      font-size: 12px;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }

    td {
      display: flex;
      justify-content: flex-start;

      border-bottom: 1px solid #eee;
      position: relative;
    }

    td:before {
      padding-right: 10px;
      flex: 1;
    }

    /*
		Label the data
		*/
    td:nth-of-type(1):before {
      content: "Day";
    }
    td:nth-of-type(2):before {
      content: "Morning Shift - 8am to 8pm	";
    }
    td:nth-of-type(3):before {
      content: "Morning Shift Secondary - 8am to 8pm";
    }
    td:nth-of-type(4):before {
      content: "Night Shift - 8pm to 8am	";
    }
    td:nth-of-type(5):before {
      content: "Night Shift Secondary - 8pm to 8am?";
    }
    &.companies {
      td:nth-of-type(1):before {
        content: "Name";
      }
      td:nth-of-type(2):before {
        content: "Code";
      }
      td:nth-of-type(3):before {
        content: "Slug";
      }
      td:nth-of-type(4):before {
        content: "Has Emergency Access";
      }
      td:nth-of-type(5):before {
        content: "Actions: ";
      }
    }
    &.nurses {
      td:nth-of-type(1):before {
        content: "Name";
      }
      td:nth-of-type(2):before {
        content: "Last name";
      }
      td:nth-of-type(3):before {
        content: "Email";
      }
      td:nth-of-type(4):before {
        content: "Actions: ";
      }
    }
    &.faq {
      td:nth-of-type(1):before {
        content: "Title";
      }
      td:nth-of-type(2):before {
        content: "Content";
      }
      td:nth-of-type(3):before {
        content: "Category";
      }
      td:nth-of-type(4):before {
        content: "Actions: ";
      }
    }
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    body {
      padding: 0;
      margin: 0;
      width: 320px;
    }
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body {
      width: 495px;
    }
  }
}
