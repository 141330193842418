.sp-hero-top {
  background-image: url(https://docto-files.s3-ap-southeast-2.amazonaws.com/dermatology-hero.jpg);
}

.sp-hero-mid {
  background-image: url(https://docto-files.s3-ap-southeast-2.amazonaws.com/specialty-phone-hero.jpg);
}

.sp-hero {
  padding: 100px 0;
  background-color: #444;
  background-size: cover;
  background-position: center center;
  color: $color-white;

  h1,
  h2 {
    font-size: 46px;
    color: $color-white;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1;
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }

  h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: normal;
    color: $color-white;
    margin-bottom: 25px;
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 0;
    @media screen and (max-width: 992px) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .book-now-block {
    max-width: 280px;
    margin: 0 auto;

    .book-now-body {
      padding: 20px 20px 30px;
      text-align: center;
      text-align: center;
      background: rgb(26, 134, 161);
      background: linear-gradient(
        90deg,
        rgba(26, 134, 161, 0.9) 0%,
        rgba(34, 65, 118, 0.8) 100%
      );
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      h3 {
        color: $color-white;
        font-size: 28px;
        margin-bottom: 15px;
      }

      p {
        color: $color-white;
        font-size: 16px;
      }
    }

    .book-now-button {
      button {
        display: block;
        width: 100%;
        background-color: $color-danger-flat;
        padding: 15px 20px;
        color: $color-white;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

        &:hover {
          background-color: darken($color-danger-flat, 5%);
        }
      }
    }
  }
}

.sp-section-white,
.sp-section-light-grey {
  &.sp-section-light-grey {
    background-color: $color-lighter-grey;
  }

  &.sp-light-grey-top {
    padding: 200px 0 0;
    background-color: $color-lighter-grey;
  }

  padding: 50px 0 0;
  background-color: #fff;

  .sp-section-white-info {
    margin-bottom: 50px;
  }

  .mock-bottom {
    text-align: center;

    img {
      width: 100%;
      max-width: 260px;
    }
  }

  .mock-right {
    text-align: right;

    img {
      width: 100%;
      max-width: 320px;
      margin-right: 40px;
      margin-bottom: -3px;
    }
  }

  h2 {
    font-size: 46px;
    color: $color-dark-grey;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 0;
    color: $color-dark-grey;
  }

  ul {
    margin: 35px 0 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.6;
      margin-bottom: 12px;
      color: $color-dark-grey;
      display: flex;
      align-items: center;
      &:before {
        background: url("../../assets/images/check-circle-red.svg");
        content: " ";
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        margin-right: 5px;
        margin-top: -3px;
        flex: 0 0 auto;
      }

      &.negative {
        &:before {
          background-image: url(../../assets/images/plus-circle-red.svg);
        }
      }

      img {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

.sp-section-blue-grad {
  padding: 170px 0 0;
  @media (max-width: 768px) {
    padding: 150px 0 0;
  }
  background: rgb(235, 252, 255);
  background: linear-gradient(
    90deg,
    rgba(235, 252, 255, 1) 0%,
    rgba(18, 62, 125, 1) 100%
  );
  &.full-phone-img {
    padding: 140px 0 0;
    padding-bottom: 40px;
    @media (max-width: 920px) {
      padding: 100px 0 0;
      padding-bottom: 40px;
    }
  }
  .row-flex {
    display: flex;
    align-items: center;
  }

  .mock-img {
    display: block;
    margin-left: auto;
    max-width: 320px;
  }

  .sb-hero-text {
    color: #fff;

    span {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
      display: block;
    }

    h1 {
      font-size: 44px;
      color: #fff;
      font-weight: bold;
      line-height: 1.2;
    }
  }

  .button-wrap {
    width: 100%;
    margin-top: 40px;
  }

  .btn-default {
    display: block;
    width: 100%;
    max-width: 380px;
    height: auto;
    margin: 0;
    background-color: $color-danger-flat;
    color: $color-white;
    font-size: 22px;
    text-transform: uppercase;
    padding: 20px;
    border-radius: 100px;
    font-weight: 600;

    &:hover {
      background-color: darken($color-danger-flat, 5%);
      color: white;
    }
  }

  .btn-teal {
    display: block;
    width: 100%;
    max-width: 380px;
    height: auto;
    margin: 0;
    background-color: #10a6bd;
    color: $color-white;
    font-size: 22px;
    text-transform: uppercase;
    padding: 20px;
    border-radius: 100px;
    font-weight: 600;

    &:hover {
      background-color: darken(#10a6bd, 5%);
      color: white;
    }
  }

  @media screen and (max-width: 920px) {
    background: rgb(235, 252, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(18, 62, 125, 1) 100%
    );

    .mock-img {
      width: 90%;
    }

    .row-flex {
      position: relative;
      flex-direction: column-reverse;
    }

    .sb-hero-text {
      text-align: center;
      position: initial;

      span {
        font-size: 20px;
      }

      h2 {
        font-size: 34px;
      }
    }

    .mock-img {
      margin: auto;
    }

    .button-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 20px;
      &.two-buttons {
        bottom: 40px !important;
      }

      .btn-default,
      .btn-teal {
        border-radius: 6px;
        font-size: 18px;
        max-width: 450px;
        margin: auto;
        padding: 15px 0;
      }
      &:hover {
        color: white;
      }
    }
  }
}

.sp-section-blue {
  padding: 100px 0;
  background: url("../../assets/images/spec-logo-bg.png"), #252665; /* Old browsers */
  background: url("../../assets/images/spec-logo-bg.png"),
    -moz-linear-gradient(-45deg, #16a3b3 0%, #252665 100%); /* FF3.6-15 */
  background: url("../../assets/images/spec-logo-bg.png"),
    -webkit-linear-gradient(-45deg, #16a3b3 0%, #252665 100%); /* Chrome10-25,Safari5.1-6 */
  background: url("../../assets/images/spec-logo-bg.png"),
    linear-gradient(135deg, #16a3b3 0%, #252665 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: left top;
  background-repeat: no-repeat;
  text-align: center;

  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: $color-white;
  }

  p {
    max-width: 820px;
    margin: 20px auto;
    text-align: center;
    font-size: 22px;
    font-weight: 300;
    color: $color-white;
  }
  .btn-round-white {
    display: inline-block;
    height: 45px;
    margin: 30px auto 0;
    padding: 0 40px;
    background-color: $color-white !important;
    border-radius: 24px;
    line-height: 45px;
    color: $color-doc-brand !important;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.sp-section-centered {
  text-align: center;
  padding: 120px 0;

  h2 {
    margin-bottom: 45px;
  }

  p {
    max-width: 820px;
    margin: 20px auto;
    text-align: center;
    font-size: 22px;
    font-weight: 300;
  }

  ul {
    max-width: 720px;
    margin: 0 auto;
    text-align: left;

    li {
      align-items: flex-start;
      font-size: 22px;
      margin-bottom: 18px;

      &::before {
        margin-top: 7px;
      }
    }
  }

  .btn-info {
    margin-top: 35px;
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    border-radius: 26px;
    padding: 0 40px;
  }
}

.sp-section-faq {
  padding: 100px 0;
  background-color: $color-white;

  h2 {
    font-size: 46px;
    color: $color-dark-grey;
    font-weight: bold;
    margin-bottom: 35px;

    span {
      font-weight: 300;
    }
  }

  .faq-item {
    margin-bottom: 25px;
    padding-right: 35px;

    .faq-item-question {
      position: relative;
      font-size: 16px;
      margin-bottom: 15px;
      padding-right: 25px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        background-image: url(../../assets/images/plus-grey.svg);
        background-repeat: no-repeat;
      }
    }

    .faq-item-answer {
      display: none;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: 50px;
    }

    &.active {
      .faq-item-answer {
        display: block;
      }

      img {
        display: none;
      }
    }
  }
}

//page-consult-dr-now addons
.consult-dr-now {
  h2 {
    font-weight: 600;
  }

  .sp-light-grey-top {
    .cdn-hero-text {
      padding-top: 40px;

      ul {
        margin-bottom: 50px;
      }

      a {
        display: block;
        color: $color-chat-blue;
        margin-bottom: 10px;
        text-decoration: underline;
      }

      .btn-info {
        font-size: 18px;
        padding: 15px 30px;
        min-width: 300px;
      }

      p.small {
        margin-top: 10px;
        font-size: 80%;
      }
    }
  }

  .sp-section-blue {
    padding: 120px 0;

    p {
      line-height: 1.5;
    }

    .btn-round-white {
      font-size: 16px;
      text-transform: none;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
    }
  }
}

.sp-section-how-it-works {
  padding: 75px 0;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 920px) {
    padding: 35px 20px;
    margin-top: 80px;
  }

  h2 {
    text-align: center;
  }

  ul {
    max-width: 740px;
    margin: 0 auto;

    li {
      position: relative;
      color: $color-dark-grey;
      font-size: 22px;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 30px;
      padding-left: 50px;

      &:before {
        display: none;
      }

      span {
        font-size: 50px;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -25px;
        line-height: 1;
      }
    }
  }

  p {
    max-width: 740px;
    margin: 0 auto;
    color: $color-dark-grey;
    font-size: 22px;
    line-height: 1.5;
    font-weight: 400;
  }
}
.children-advice {
  text-align: center;
  padding: 25px;
  line-height: 1.5;
  display: flex;
  flex-direction: row;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}
.center-subtitle {
  text-align: center;
  max-width: 60%;
  margin: auto;
  @media (max-width: 720px) {
    max-width: 90%;
  }
}
.sp-section-request-block {
  padding: 0 0 50px;

  .row-flex {
    display: flex;

    .col-sm-4 {
      min-height: 100%;
    }
  }

  @media screen and (max-width: 920px) {
    padding-bottom: 0;
    .row-flex {
      flex-wrap: wrap;
    }

    .col-sm-4 {
      width: 100%;
      margin-bottom: 100px;
    }
  }

  .rb-square {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-size: cover !important;
    background-position: center 45% !important;
    padding: 50px 40px 30px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 400px;
    @media screen and (max-width: 768px) {
      min-height: 200px;
    }

    &.rb-square-a {
      background: #3b577c;
      background: linear-gradient(
          rgb(82, 177, 182, 0.75),
          rgb(59, 87, 124, 0.75)
        ),
        url(../../assets/images/Paediatrics_General.jpg);
      background: linear-gradient(
          rgb(82, 177, 182, 0.75),
          rgb(59, 87, 124, 0.75)
        ),
        url(../../assets/images/Paediatrics_General.jpg);
    }

    &.rb-square-b {
      background: #3b577c;
      background: linear-gradient(
          rgb(82, 177, 182, 0.75),
          rgb(59, 87, 124, 0.75)
        ),
        url(../../assets/images/Paediatrics_ADHD.jpg);
      background: linear-gradient(
          rgb(82, 177, 182, 0.75),
          rgb(59, 87, 124, 0.75)
        ),
        url(../../assets/images/Paediatrics_ADHD.jpg);
    }

    &.rb-square-c {
      background: #3b577c;
      background: linear-gradient(
          rgb(82, 177, 182, 0.75),
          rgb(59, 87, 124, 0.75)
        ),
        url(../../assets/images/Paediatrics_Child.jpg);
      background: linear-gradient(
          rgb(82, 177, 182, 0.75),
          rgb(59, 87, 124, 0.75)
        ),
        url(../../assets/images/Paediatrics_Child.jpg);
    }

    h3 {
      color: $color-white;
      font-size: 28px;
      margin-bottom: 30px;
    }

    p {
      color: $color-white;
      font-size: 14px;
      margin-bottom: 10px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      background-color: $color-danger-flat;
      padding: 15px 20px;
      color: $color-white;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      &:hover {
        text-decoration: none;
        background-color: darken($color-danger-flat, 5%);
      }
    }
  }
}
