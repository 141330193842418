.in-chat {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f1f1f1;
  overflow: hidden;

  .chat-module {
    top: $top-bar-height-in-call;
    right: 0;
    left: 0;
    width: auto;

    &.ac-full-screen {
      width: 100%;
    }

    .chat-module__block__header {
      border-radius: 0;
    }

    .chat-module__inner {
      height: 100%;
    }

    .chat-module__block {
      height: 100%;

      .chat-module__block__body {
        height: calc(100% - 155px);

        .chat-module__block__scroll__inner {
          height: 100%;
        }
      }
    }

    .cb-actions,
    .cb-textbox {
      border-radius: 0;
    }
  }

  .chat-module__block__scroll {
    .chat-item {
      &.received {
        text-align: right;
      }

      .ci-text-bubble {
        @media only screen and (min-width: 481px) {
          display: inline-block;
          min-width: 120px;
        }
      }
    }
  }

  .cp-close,
  .sh-panel-trigger a {
    font-size: 20px !important;
  }
}
