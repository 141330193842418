//Dr Profile

.dr-profile-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-top: 80px;
  min-height: 100vh;
  background-image: url("../../assets/images/dr-profile-bg.jpg");
  background-position: right bottom;
  background-size: cover;

  .drp-scroll {
    width: 100%;
    max-height: calc(100vh - 80px);
    overflow: auto;
    padding-bottom: 40px;
  }

  .drp-panel {
    position: relative;
    width: 100%;
    max-width: 380px;
    margin: 100px auto 0;
    padding: 110px 40px 40px;
    border: none;
    border-radius: 8px;
    background: $color-white;
    color: $color-font-grey;

    .drp-img {
      position: absolute;
      top: -70px;
      left: 50%;
      width: 140px;
      height: 140px;
      margin-left: -70px;
      border-radius: 50%;
      background-image: url("../../assets/images/optimized/patient_placeholder.webp");
      background-position: center;

      background-size: cover;
      border: 2px solid $color-white;
    }

    .drp-name {
      text-align: center;
      margin-bottom: 20px;

      span {
        display: block;
        font-size: 1.125em;
        margin-bottom: 5px;
        font-weight: 600;
      }
    }

    .drp-detail {
      margin-bottom: 20px;
      text-align: center;

      span {
        display: block;
        font-size: 0.8em;
        color: $color-true-black;
        font-weight: 600;
      }
    }

    .drp-rating {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;

      span {
        display: block;
        font-size: 1.45em;
        color: $color-doc-brand;
        font-weight: 600;
        margin: 0 5px;
      }

      i {
        color: $color-font-grey;
        margin: 0 5px;
        font-size: 14px;
      }
    }

    .drp-desc {
      margin-bottom: 40px;

      p {
        color: $color-mute-grey;
        font-size: 0.75em;
        margin-bottom: 15px;
      }
    }

    .drp-footer {
      button {
        display: block;
        width: 100%;
        padding: 6px 10px;
        font-size: 0.875em;
        text-transform: uppercase;
        font-weight: 600;
      }

      p {
        font-size: 1.1em;
        margin: 12px 0;
        text-align: center;
        font-weight: 400;

        a {
          color: $color-doc-brand-sec;

          &:hover {
            color: darken($color-doc-brand-sec, 10%);
          }
        }
      }
    }
  }

  .drp-outer {
    text-align: center;
    padding-top: 10px;

    p {
      font-size: 0.75em;
      color: $color-white;
      font-weight: 400;
      margin-bottom: 15px;
    }

    img {
      width: 120px;
    }
  }
}
