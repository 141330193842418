.pricing-hero {
  margin-bottom: 160px;
  width: 100%;
  min-height: 550px;
  background: url(../../assets/images/pricing-hero.jpg);
  background-size: cover;
  background-position: center bottom;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  text-align: center;

  .wrap {
    width: 100%;
  }

  .billing-plans-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .bp-side {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 60px;

      img {
        height: 50px;
        width: 158px;
      }

      h2 {
        margin-bottom: 45px !important;
        font-weight: 300;
        color: $color-white;
        font-size: 2.75em;
        line-height: 1.1;
      }

      .billing-plans-header {
        border: none;
        flex-direction: column;

        a {
          color: $color-white;
          padding: 0 0 3px;
          margin: 0 0 40px;
          width: auto;
          font-size: 1.675em;
          font-weight: 300;

          span {
            display: inline-block;
          }

          &:hover,
          &.active {
            span {
              font-weight: 400;
              color: $color-white;
            }
          }
        }
      }
    }

    .billing-plans-body {
      width: 100%;
      align-items: flex-end;
      margin-bottom: -150px;

      .billing-plan {
        width: 33.3%;
        padding: 20px;
        margin: 0;

        &:last-child {
          margin-right: auto;
        }

        h4 {
          margin: 0 0 20px;
          color: $color-white;
          text-transform: uppercase;
          font-size: 1.3em;
          text-align: center;
        }

        .bp-bpdy {
          display: flex;
          flex-direction: column;
          min-height: 360px;

          .bp-price {
            sup {
              top: 0.5em;
            }
          }
        }
      }
    }

    #billing-free {
      .billing-plan {
        h4 {
          display: none;
        }
      }
    }

    #billing-business {
      .billing-plan {
        width: 33.3%;
      }
    }
  }

  .app-store-link {
    display: none;
    padding: 20px 0 0;
    text-align: center;

    a {
      display: inline-block;

      img {
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .pricing-hero {
    .billing-plans-wrap {
      .billing-plans-body {
        .billing-plan {
          padding: 25px 10px;
        }
      }

      #billing-business {
        .billing-plan {
          width: 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .pricing-hero {
    margin-top: 0;

    .wrap {
    }

    .billing-plans-wrap {
      flex-direction: column;

      .bp-side {
        text-align: center;

        h2 {
          margin-bottom: 30px !important;
        }

        .billing-plans-header {
          width: auto;
          margin: 0 30px;
          flex-direction: row;
          border-bottom: 1px solid #dddddd;

          a {
            width: 200px;
            margin-bottom: -5px;
          }
        }

        img {
          display: none;
        }
      }

      .billing-plans-body {
        .billing-plan:last-child {
          margin: 0;
        }
      }

      #billing-business {
        .billing-plan {
          width: 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .pricing-hero {
    .billing-plans-wrap {
      .billing-plans-body {
        .billing-plan {
          width: auto !important;
          max-width: 45% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .pricing-hero {
    margin-bottom: 0;
    padding-bottom: 50px;

    .wrap {
      padding: 0;
    }

    .billing-plans-wrap {
      margin: 0;

      .bp-side {
        margin: 0 0 50px;

        h2 {
          display: none;
        }

        .billing-plans-header {
          border: none;
          margin: 0;

          a {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            line-height: 50px;
            border: 1px solid $color-white;
            border-bottom: none;
            font-size: 1.25em;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }

            span {
              display: block;
              border: none;
              font-weight: 400;
              padding: 0;
              border-bottom: 1px solid $color-white;
            }

            &.active,
            &:hover {
              span {
                border-bottom: none;
              }

              &:after {
                position: absolute;
                width: 100%;
                height: 10px;
                content: "";
                display: block;
                background: url(../../assets/images/pricing-active-border.png);
                background-size: 100% 100%;
                background-position: center bottom;
              }
            }
          }
        }

        img {
        }
      }

      .billing-plans-body {
        margin: 0;
        flex-direction: column;
        align-items: center;

        .billing-plan {
          max-width: 400px !important;
        }
      }

      #billing-business {
        .billing-plan {
        }
      }
    }

    .app-store-link {
      display: block;
    }
  }
}
