.rsr-root {
  vertical-align: middle;
  display: inline-block;
}

.rsr--editing {
  &:hover {
    cursor: pointer;
  }
}

.rsr {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #e3e3e3;
  overflow: hidden;
}

.rsr__stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: $color-mz-yellow;
  @include transition(all 0.01s);
}

.rsr__caption {
  font-size: 1.25em;
  vertical-align: middle;
  margin-right: 0.5em;
}

.rsr--disabled {
  .rsr-container:hover {
    cursor: not-allowed;
  }
}
