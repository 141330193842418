@font-face {
  font-family: "Digital-Seven";
  src: url("../../assets/fonts/Digital-7.eot");
  src: url("../../assets/fonts/Digital-7.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/Digital-7.woff") format("woff"),
    url("../../assets/fonts/Digital-7.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
