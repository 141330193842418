//Theme Vars
$theme-name: r2s;
$hisa-color-brand: #fff;
$hisa-color-text: #fff;
$hisa-color-solid-btn: #cb1c00;
$hisa-color-lined-btn: #fff;
$hisa-color-top-bar: #000;

.theme-#{$theme-name} {
  //color: $hisa-color-text;
  //background: #000;

  //Top Bar
  .top-bar {
    position: fixed;
    top: 0;
    background: $hisa-color-top-bar !important;
    //background: none;
    color: $hisa-color-text !important;

    .header-title {
      display: none;
      position: initial;
      width: auto;
      margin: 0;
      padding: 0 15px;
      text-align: left;
    }
    ul.nav {
      .help-line {
        display: none;
      }
    }

    .header-title,
    .nav > li > a,
    .nav > li > span {
      color: $hisa-color-text;
    }

    .main-brand {
      display: none;
    }
  }

  .inner-brand {
    position: fixed;
    top: 60px;
    left: 20px;
    width: 120px;

    @media only screen and (max-width: 768px) {
      position: initial;
      margin: 0 auto 25px;
      display: inline-block;
    }
  }

  .responsive-menu-icon i {
    color: $hisa-color-text;
  }

  //Reg Box Logo
  .register-block-wrap {
    margin: 0;

    .cs-wrap-blank {
      background: none;
      width: auto;
      max-width: none;
      padding: 0;
      box-shadow: none;

      .rb-header .reg-logo {
        display: none;
      }

      h1 {
        margin-bottom: 25px;
      }

      p {
        color: #fff;
        font-weight: 400;
        line-height: 1.4;
      }
    }

    .rb-buttons {
      margin-top: 35px;

      .btn {
        cursor: pointer;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        min-width: 180px;
        border-radius: 20px;
        padding: 0 20px;
        border: 1px solid;
        text-align: center;
        font-weight: 300;
        margin: 0 20px;

        &.solid {
          color: #fff;
          background: $hisa-color-solid-btn;
          border-color: $hisa-color-solid-btn;
        }

        &.lined {
          color: $hisa-color-lined-btn;
          background: none;
          border-color: $hisa-color-lined-btn;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }

  //Hero
  .register-header {
    margin: 0;
    min-height: 100vh;
    &.company-signup {
      background-image: url("../../assets/images/themes/#{$theme-name}/header.jpg");
    }
  }

  //Modal
  .modal-window {
    z-index: 4;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    margin: 80px 0 0;
    height: auto;

    .modal {
      width: 100%;
      margin: 0 auto 0;
      border: none;
      pointer-events: all;
    }
  }
}
