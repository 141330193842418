.react-phone-number-input__row {
  /* This is done to stretch `.rrui__select__options` */
  /* to the full width of `.react-phone-number-input__phone`. */
  /* Will be moved to `rrui.css` in version 2.x. */
  position: relative;

  /* This is done to stretch the contents of this component */
  display: flex;
  align-items: center;
}

/* Overrides `width: 100%` setting from `react-resonsive-ui` */
.react-phone-number-input__country {
  width: auto;
}

/* The phone number input stretches to fill all empty space */
.react-phone-number-input__phone,
/* The country select, when expanded, stretches to fill all empty space. */
/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input__country.rrui__select--expanded {
  flex: 1;
}

.react-phone-number-input__phone {
  font-size: inherit;
}

/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input__country.rrui__select--collapsed {
  margin-right: 0.5em;
}

/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input__country .rrui__select__autocomplete {
  width: 100%;
}

/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input__country,
.react-phone-number-input__country:not(.react-phone-number-input__country--native-expanded)
  .rrui__input {
  /* This is done to stretch `.rrui__select__options` */
  /* to the full width of `.react-phone-number-input__phone` */
  /* rather than to the full width of `.rrui__select` */
  /* because the `.rrui__select` is contracted */
  /* as soon as a user selects a country which caused */
  /* the `.rrui__select__options` to contract too */
  /* while the list collapse animation is still in progress. */
  /* The `position: relative` container is now `.react-phone-number-input`. */
  /* The `:not(.react-phone-number-input__country--native-expanded)` selector */
  /* is required because native `<select/>` is positioned absolutely. */
  position: static;
}

/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input .rrui__select__arrow {
  margin-top: 0.3em;
  margin-left: 0.3em;
}

/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input .rrui__select__option {
  display: flex;
  align-items: center;
}

/* Will be moved to `rrui.css` in version 2.x. */
.react-phone-number-input .rrui__select__option-icon {
  margin-bottom: 0.1em;
}

.react-phone-number-input__icon {
  /* The flag icon size is 4x3 hence the exact `width` and `height` values */
  width: 1.24em;
  height: 0.93em;
  /* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
  box-sizing: content-box;
}

.react-phone-number-input__icon--international {
  /* The international icon size is square */
  /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
  width: calc(0.93em + 2px);
  height: calc(0.93em + 2px);

  /* The international icon size is square hence the exact `padding` value */
  /* for precise alignment with `4x3` sized country flags. */
  padding-left: 0.155em;
  padding-right: 0.155em;

  border: none;
}

.react-phone-number-input__error {
  margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
  margin-top: calc(0.3rem);
  color: #d30f00;
}

.react-phone-number-input__icon-image {
  max-width: 100%;
  max-height: 100%;
}

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button {
  margin: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
}

.react-phone-number-input__ext-input {
  width: 3em;
}

.react-phone-number-input__ext {
  white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
  margin-left: 0.5em;
}

/* The phone number input should shrink
   to make room for the extension input */
.react-phone-number-input__phone {
  min-width: 0;
}

/* Native `<select/>`. */

.react-phone-number-input__country--native {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}

.react-phone-number-input__country-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.react-phone-number-input__country-select-arrow {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #b8bdc4;
  opacity: 0.7;
  transition: color 0.1s;
}

.react-phone-number-input__country-select:focus
  + .react-phone-number-input__country-select-arrow {
  color: #03b2cb;
}

/* Remove "--native" for v 2.x */
.react-phone-number-input__phone--native {
  appearance: none;
  margin-bottom: 0 !important;
  transition: border 0.1s;
}

/* Remove "--native" for v 2.x */
.react-phone-number-input__phone--native:focus {
  border-color: #03b2cb;
}

.react-phone-number-input__phone--disabled {
  cursor: default;
}

.react-phone-number-input__phone--invalid,
.react-phone-number-input__phone--invalid:focus {
  border-color: #eb2010;
}

/* Remove "--native" for v 2.x */
/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__phone--native:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}
