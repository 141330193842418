.admin-dash {
  background-color: $color-white;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;

  .admin-header {
    display: flex;

    .top-actions {
      margin: 0 0 0 auto;

      .btn {
        white-space: nowrap;
      }
    }
  }
}

.rt-td .loader-animation {
  width: 100%;
  height: 12px;
}

.admin-search-bar {
  display: flex;
  margin: 12px 0;

  .form-styled {
    font-size: 13px;
    margin: 0 5px 0 0;
    height: 40px;
  }

  .input-group {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0 5px 0 0;

    .form-styled,
    input[type="text"] {
      width: 175px;
      height: 40px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      margin: 0;
      font-size: 13px;

      &:focus {
        border-right: none;
      }
    }

    .btn {
      border-radius: 0;
      align-self: stretch;

      &:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;

    select.form-styled {
      width: 100%;
      margin: 0 0 10px;
    }

    .input-group {
      width: calc(100% - 65px);

      .form-styled,
      input[type="text"] {
        flex: 1;
      }
    }

    .btn-clear {
      width: 60px;
    }
  }
}

.actions-cell {
  display: flex;
  justify-content: center;

  button,
  a {
    display: flex;
    width: 30px;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 3px;

    i {
      line-height: 1;
      height: auto;
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    justify-content: flex-start;

    button {
      width: 40px;
      height: 40px;

      i {
        font-size: 20px;
      }
    }
  }
}
