.sm {
  &-profile-view {
    text-align: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 15px;
    }

    .doc-title,
    .doc-degree {
      display: block;
      font-size: 20px;
      margin: 0;
      font-weight: 400;
      line-height: 1.3;
    }

    .doc-specialty {
      display: block;
      margin: 15px 0;
      font-size: 13px;
      text-transform: uppercase;
      color: $color-doc-brand;
    }
  }

  &-step-item {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    color: $color-mute-grey;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      opacity: 0.7;
    }

    .step-info {
      flex: 1;
      padding: 0 5px 0 15px;
      margin: 0 5px 15px 0;

      &-item {
        display: block;
        font-size: 14px;
        margin: 0;
        font-weight: 400;
        line-height: 1.3;
        word-break: break-all;
      }
    }

    .edit-link {
      margin-left: auto;
      text-transform: uppercase;
      color: $color-mute-grey;
      font-weight: 400;
      font-size: 14px;

      i {
        color: $color-doc-brand-sec;
        vertical-align: middle;
        margin-right: 2px;
      }

      &:hover {
        text-decoration: none;
        color: $color-darker-grey;
      }
    }

    .sm-form {
      margin: 5px 0 0;

      .form-control-upload {
        border: none;
        margin: 0;
        padding: 0;

        input[type="file"],
        input[type="file"]:focus,
        input[type="file"]:active {
          width: calc(100% - 5px);
          margin: 0 0 0 5px;
        }

        input:disabled {
          color: $color-mute-grey;

          ~ label.trigger {
            cursor: not-allowed;
            background-color: lighten($color-mute-grey, 15%);
          }
        }

        i {
          display: none;
        }
      }
      .link-add {
        display: none;
      }
    }
  }

  &-form {
    margin: 15px 0;

    &-title {
      display: block;
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;
    }

    &-link {
      display: block;
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      color: $color-darker-grey !important;

      &:hover {
        color: $color-darker-grey;
        text-decoration: underline;
      }
    }

    ::-webkit-input-placeholder {
      /* Chrome */
      font-size: 12px;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-size: 12px;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 12px;
    }
    :-moz-placeholder {
      /* Firefox 4 - 18 */
      font-size: 12px;
    }

    .form-group {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 6px;

      input,
      select,
      textarea {
        flex: 1;
        width: auto;
        height: 35px;
        margin-right: 5px;
        background-color: transparent;
        font-size: 13px;

        &:last-child {
          margin: 0;
        }
      }

      select {
        font-size: 12px;
        font-weight: 400;
      }

      textarea {
        height: 80px;
        font-weight: 400;
      }

      .react-phone-number-input {
        width: 100%;
      }
    }

    .sm-message {
      margin-bottom: 5px;
    }

    .form-control-upload {
      flex: 1;
      height: 35px;
      font-size: 13px;

      input {
        margin: 0;
        padding: 0;
        font-weight: 400;
      }

      i {
        height: 34px;
        line-height: 34px;
      }
    }

    .link-add {
      color: $color-dark-grey;
      line-height: 35px;
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-message {
    font-size: 15px;
    text-align: center;
    color: $color-darker-grey;
    font-weight: 400;
    line-height: 1.3;

    margin: 15px 0;

    a {
      font-weight: 500;
      color: $color-darker-grey;
      text-decoration: underline;

      &:hover {
        color: $color-darker-grey;
        text-decoration: underline;
      }
    }

    &.add-on {
      position: relative;
      padding-right: 25px;
      text-align: left;

      i {
        position: absolute;
        top: -2px;
        right: 0;
        width: 18px;
        font-size: 20px;
        text-align: center;
      }
    }
  }
}

h2.sm-header {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  color: #555555;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .modal-window {
    .special-modal {
      max-width: none;

      .md-body {
        padding-left: 20px;
        padding-right: 20px;
      }

      .md-header {
        .mdh-close {
          line-height: 50px;
          width: 35px;
        }

        .mdh-link {
          font-size: 22px;

          &:last-child {
            padding-right: 40px;
          }
        }
      }
    }
  }

  .sm-step-item {
    .edit-link {
      color: $color-doc-brand-sec;

      &:hover {
        color: darken($color-doc-brand-sec, 10%);
      }

      i {
        display: none;
      }
    }
  }
}
