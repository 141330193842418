@media only screen and (max-width: 767px) {
  .profile-holder {
    .prof-menu {
      a {
        display: block;
        line-height: 30px;
        &.active {
          border-left: 3px solid $color-doc-brand-sec;
          border-bottom: none;
        }
        &:hover {
          border-left: 3px solid $color-darker-grey;
          border-bottom: none;
        }
      }
    }

    .prof-body {
      .form-group {
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  .profile-holder {
    .prof-body {
      .form-group {
        text-align: left;

        label,
        input,
        select,
        textarea,
        .select,
        Select-control {
          display: block;
          text-align: left;
          width: 100% !important;
          margin: 0;
        }

        .multiple-wrap {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .col-2-2 {
            width: 48% !important;
          }

          .col-1-4 {
            width: 25% !important ;
          }

          .col-3-4 {
            width: 71% !important;
          }
        }

        &.has-error {
          &:after {
            margin: 30px 0 0 0;
            left: initial;
            right: -12px;
          }
        }
      }

      .react-phone-number-input {
        width: 100% !important;

        .react-phone-number-input__country-select {
          width: 40px !important;
        }

        input[type="tel"] {
          width: auto;
        }
      }

      .cc-actions {
        width: 100%;
        margin: 15px auto 0;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .profile-holder {
    .prof-body {
      .form-group {
        select {
          width: 330px;
        }
        input,
        textarea,
        .Select-control {
          width: 350px;
        }
        label {
          text-align: left;
          width: 350px;
        }
        .col-2-4 {
          width: 143px;
          margin-right: 4px;
        }
        .col-3-4 {
          width: 207px;
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .profile-holder {
    .prof-body {
      .form-group {
        select {
          width: 300px;
        }
        input,
        textarea,
        .Select-control {
          width: 350px;
        }
        label {
          text-align: left;
          width: 350px;
        }
        .col-2-4 {
          width: 125px;
          margin-right: 4px;
        }
        .col-3-4 {
          width: 175px;
        }
      }
    }
  }
}
