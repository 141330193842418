.billing-plans-wrap {
  margin-top: 40px;

  h2 {
    margin: 0 0 40px !important;
    color: #8f8f8f;
    font-weight: 400;
  }

  .delete-link {
    font-weight: 400;
    display: inline-block;
    margin: 10px 0 0;
    color: #888;
    font-size: 13px;
    text-transform: uppercase;
  }

  .billing-plans-header {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid $color-border-grey;

    a {
      width: 95px;
      margin: 0 35px -2px;
      font-size: 1.24em;
      color: $color-font;

      span {
        padding: 0 0 6px;
        display: block;
        border-bottom: 3px solid transparent;
      }

      &:hover,
      &.active {
        text-decoration: none;

        span {
          color: $color-eclipse;
          border-bottom: 3px solid $color-doc-brand-sec;
        }
      }
    }
  }

  .billing-plans-body {
    display: flex;
    justify-content: center;

    .billing-plan {
      width: 33.3%;
      padding: 15px;
      margin: 0;

      h4 {
        text-transform: uppercase;
        font-size: 1em;
        margin: 40px 0 35px;
        color: #8f8f8f;
        font-weight: 400;
      }

      .bp-bpdy {
        position: relative;
        background: $color-white;
        padding: 35px 25px;
        box-shadow: 0px 20px 75px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        text-align: left;

        .bp-price {
          color: $color-doc-brand-sec;
          margin-bottom: 18px;
          text-align: center;
          top: 0.5em;

          sup {
            display: inline-block;
            vertical-align: top;
            font-size: 25px;
          }

          large {
            display: inline-block;
            vertical-align: top;
            font-size: 50px;
            line-height: 40px;
          }

          span {
            display: block;
            font-size: 20px;
          }
        }

        h5 {
          color: #13bec4;
          line-height: 1.4;
          font-size: 1.25em;
          text-align: center;
          margin-bottom: 20px;
        }

        p {
          margin: 0px 0px 15px 0px;
          font-size: 0.72em;
          font-weight: 400;
          line-height: 1.2;
        }

        strong {
          display: block;
          margin: 0px -10px 18px;
          font-size: 0.72em;
          line-height: 1.2;
          text-align: justify;
        }

        a {
          display: block;
          font-size: 1em;
          margin-top: 25px;
          text-align: center;

          &:hover {
            color: $color-white;
          }
        }

        .delete-link {
          position: absolute;
          bottom: 15px;
          left: 0;
          width: 100%;
          margin: 10px 0 0;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 400;
          color: #888;

          &:hover {
            color: #565656;
            text-decoration: underline;
          }
        }
      }
    }
  }

  #billing-business {
    .billing-plan {
      width: 42%;
      padding: 25px;

      .bp-bpdy {
        padding: 35px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .billing-container {
    margin-bottom: 0;
    padding-bottom: 50px;

    .wrap {
      padding: 0;
    }

    .billing-plans-wrap {
      margin: 0;

      .bp-side {
        .billing-plans-header {
          border: none;
          margin: 0;

          a {
            position: relative;
            width: auto;
            margin: 0 10px;
            padding: 0;
            line-height: 50px;
            font-size: 1em;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }

            span {
              display: block;
              border: none;
              font-weight: 400;
              padding: 0;
            }

            &.active,
            &:hover {
              span {
                border-bottom: 2px solid $color-doc-brand-sec;
              }

              &:after {
                position: absolute;
                width: 100%;
                height: 10px;
                content: "";
                display: block;
                background: url(../../assets/images/pricing-active-border.png);
                background-size: 100% 100%;
                background-position: center bottom;
              }
            }
          }
        }

        img {
        }
      }

      .billing-plans-body {
        margin: 0;
        flex-direction: column;
        align-items: center;

        .billing-plan {
          max-width: 400px !important;
          width: 100%;
          padding: 0;
        }
      }

      #billing-business {
        .billing-plan {
          max-width: 400px !important;
          width: 100%;
          padding: 0;
        }
      }
    }

    .app-store-link {
      display: block;
    }
  }
}
