.menu-item-popup,
.item-popup {
  position: relative;
  .popup-wrap {
    display: inline-block;
    vertical-align: middle;
  }
}

.login-popup,
.block-popup {
  z-index: 99;
  position: absolute;
  top: 100%;
  right: 0;
  margin: 15px 15px 0 0;
  width: auto;
  height: auto;
  padding: 20px;
  background: #f2f2f2;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid #b8b8b8;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-style: inset;
    -webkit-transform: rotate(360deg);
  }

  &:after {
    z-index: 2;
    top: -8px;
    right: 5px;
    border-color: transparent transparent #f2f2f2 transparent;
  }

  &:before {
    z-index: 1;
    top: -9px;
    right: 5px;
    border-color: transparent transparent #b8b8b8 transparent;
  }

  .close-resp-login {
    display: none;
  }

  input {
    width: 320px !important;
  }

  .create-footer {
    margin: 10px -20px -20px;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.1);

    a {
      color: #272264;
      display: block;
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: center;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
