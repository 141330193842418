// Profile

.profile-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: $top-bar-height 20px;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  .account-body {
    min-height: 300px;
    margin: auto;
    padding: 10%;
    .prof-load {
      display: flex;
      width: 100%;
      max-width: 450px;
      margin: 0 auto 12px;
      justify-content: space-between;

      .loader-animation {
        height: 8px;
      }

      .left {
        width: 30%;
      }
      .right {
        width: 65%;
      }

      .wide {
        width: 100%;
        max-width: 450px;
      }
    }
  }
  .prof-menu {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: bottom;
    margin: 30px 0 0;
    width: 100%;
    max-width: 850px;
    height: auto;
    border-bottom: 1px solid $color-border-grey;
    text-align: left;

    a {
      display: inline-block;
      font-size: 0.875em;
      text-transform: uppercase;
      padding: 0 5px 10px;
      margin-right: 5px;
      color: $color-dark-grey;

      &.active {
        font-weight: 500;
        color: $color-darker-grey;
        border-bottom: 3px solid $color-doc-brand-sec;
      }

      &:hover {
        text-decoration: none;
        color: $color-darker-grey;
        border-bottom: 3px solid $color-darker-grey;
      }
    }
  }

  .prof-body {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: bottom;
    margin: 15px 0 0;
    width: 100%;
    max-width: 850px;
    min-height: 400px;
    height: auto;
    background: $color-white;
    padding: 20px 20px 40px;
    @extend .box-shad-main;
    @include border-top-radius($border-radius);
    @include border-bottom-radius($border-radius);

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: table;
      width: 100%;
      height: auto;
      height: 6px;
      background: $color-doc-brand;
      @include border-top-radius(0);
      @include border-bottom-radius($border-radius);
    }

    h1 {
      margin: 10px auto 10px;
    }

    h2 {
      font-size: 1.5em;
      line-height: 1.3;
      margin: 30px auto 15px;
    }

    .header-text {
      font-size: 0.875em;
      width: 80%;
      padding-top: 10px;
      margin: -5px auto 30px;
      border-top: 1px solid #f1f1f1;
    }

    .header-lined {
      width: 120px;
      height: 4px;
      margin: 10px auto 30px;
      background: $color-mid-grey;
    }

    .profile-img {
      text-align: center;
      margin-bottom: 30px;

      img {
        width: 150px;
      }
    }

    .message {
      margin: 0 60px 30px;
      padding: 6px;

      &.danger {
        border-bottom: 1px solid $color-danger;
        color: $color-danger;
      }

      &.info {
        border-bottom: 1px solid $color-danger;
        color: $color-info;
      }

      span {
        line-height: 1, 4;
        font-size: 0.8em;
        text-transform: uppercase;
      }
    }

    .form-group {
      margin-bottom: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      label {
        display: inline-block;
        width: 120px;
        text-align: right;
        margin-right: 10px;
        font-size: 0.9em;
        line-height: 1.3;
      }
      .multiple-wrap {
        width: 320px;
        text-align: left;
      }
      .col-1-4 {
        width: 75px;
        margin-right: 5px;
      }

      .col-2-4 {
        width: 120px;
        margin-right: 5px;
      }

      .col-3-4 {
        width: 240px;
      }

      .col-2-2 {
        width: 156px;
        margin-right: 7px;

        &:last-child {
          margin: 0;
        }
      }

      .hidden-input {
        width: 320px;
        min-height: 42px;
        text-align: left;
        padding: 12px 0;

        span {
          font-size: 0.875em;
          font-style: italic;
          font-weight: 700;

          a {
            color: $color-doc-brand-sec;
            font-weight: 300;
            text-decoration: underline;
          }
        }

        &.open {
        }
      }

      input,
      select,
      textarea,
      .Select-control {
        font: inherit;
        width: 320px;
        border: none;
        outline: none;
        border-radius: 3px;
        height: 40px;
        background: rgba($color-doc-brand, 0.04);
        border: 1px solid rgba($color-doc-brand, 0.15);
        padding: 0 10px;

        &:focus {
          border: 1px solid rgba($color-doc-brand, 0.35);
        }

        &[type="checkbox"] {
          width: auto;
          height: auto;
        }
      }

      select {
        width: 322px;
      }

      textarea {
        padding: 10px;
        vertical-align: text-top;
        resize: none;
        height: 80px;
      }

      &:after {
        display: none;
        content: attr(data-alert);
        width: 322px;
        margin: 3px 0 0 134px;
        padding: 6px 10px;

        background: rgba($color-danger, 0.15);

        font-size: 0.75em;
        text-align: left;
        color: $color-danger;

        @include border-top-radius($border-radius);
        @include border-bottom-radius($border-radius);
      }

      &.alert-error {
        &:after {
          display: inline-block;
        }

        input {
          border: 1px solid $color-danger;
        }
      }

      &.centered {
        label {
          width: auto;
        }
      }

      &.has-error {
        &:after {
          position: absolute;
          top: 0;
          left: 50%;
          display: block;
          content: "*";
          width: auto;
          margin: 14px 0 0 232px;
          padding: 0;
          background: none;
          font-size: 1.4em;
          text-align: center;
          color: $color-danger;
        }
      }

      .Select--multi,
      .Select--single {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        text-align: left;

        .Select-control {
          width: 340px;
        }

        .Select-input {
          margin: 0;

          input {
            border: none;
            background: none;
            width: auto;
            padding: 0;
          }
        }

        .Select-value {
          margin: 6px 5px 5px 0;
        }
      }

      &.change-input {
        align-items: flex-start;

        label {
          margin-top: 25px;
        }

        &.passFix {
          label {
            margin-top: 13px;
          }
        }

        .hidden-input {
          a {
            float: right;
            margin-top: 5px;
            font-size: 0.875em;
            color: $color-doc-brand-sec;
            text-decoration: underline;
          }
        }
      }
    }

    .profile-alert {
      margin: 15px auto 0;
      width: 100%;
      max-width: 850px;
    }

    .react-phone-number-input {
      .react-phone-number-input__country-select {
        width: 40px !important;
      }

      input[type="tel"] {
        width: 280px;
      }
    }

    .form-message {
      span {
        display: inline-block;
        width: 462px;
        padding-left: 125px;
        font-size: 0.75em;
        text-align: left;
      }
    }

    .radio-wrap {
      display: inline-block;
      width: 342px;
      text-align: left;
      margin-left: 130px;

      input {
        vertical-align: middle;
      }
    }

    .cc-wrap {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .form-group {
        width: 100%;

        input {
          width: 100%;
          margin: 0 6px;
        }
      }

      .cc-wrap-footer {
        display: flex;
        justify-content: center;

        button {
          flex: 1;
          margin: 0 5px;
        }
        .disabled {
          background-color: grey;
        }
        a {
          flex: 1;
          margin: 0 5px;
        }
      }
    }

    .prof-actions {
      margin-top: 30px;
      text-align: center;

      button {
        margin: 0 5px;
        padding: 0 15px;
        min-width: 200px;

        i {
          margin-right: 5px;
          vertical-align: middle;
          font-size: 1.5em;
        }
      }
    }

    .delete-link {
      cursor: pointer;
      font-weight: 400;
      display: block;
      margin: 10px 0 0;
      color: #888;
      font-size: 13px;
      text-transform: uppercase;
    }

    .prof-cc {
      border-bottom: 1px solid #ecebeb;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    .cc-actions {
      display: inline-block;
      width: 320px;
      margin: 15px 0 0 120px;
      text-align: center;

      .foot-text {
        padding: 0;
        margin-top: 10px;
        text-align: center;
      }
    }

    .prof-footer {
      padding: 10px 10px 0;
      font-size: 0.8em;
      color: $color-dark-grey;
    }

    .prof-select-plan {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .plan-wrap {
        padding: 10px;
        width: 33.334%;

        .main-info {
          position: relative;
          display: block;
          min-height: 80px;
          border-radius: $border-radius;
          padding: 10px 10px 10px 50px;
          background: $color-lighter-grey;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {
            display: block;
            font-size: 0.785em;
            line-height: 1.2;
            margin-bottom: 3px;
            text-align: left;
          }

          input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
          }

          .check-icon {
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -15px;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            &:after {
              display: none;
              content: "\e934";
              color: $color-white;
              font-size: 16px;
              line-height: 30px;
            }

            &:before {
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.25);
              margin-top: 10px;
            }

            &.green {
              background: rgba($color-success, 0.45);
            }

            &.blue {
              background: rgba($color-doc-brand-sec, 0.45);
            }

            &.orange {
              background: rgba($color-warning, 0.45);
            }
          }

          input[type="radio"]:checked + i {
            &.green {
              background: $color-success;
            }

            &.blue {
              background: $color-doc-brand-sec;
            }

            &.orange {
              background: $color-warning;
            }

            &:after {
              display: block;
            }

            &:before {
              display: none;
            }
          }
        }

        .sec-info {
          padding: 10px;

          span {
            display: block;
            font-size: 0.785em;
            line-height: 1.2;
            margin-bottom: 3px;
            text-align: center;
          }
        }
      }
    }

    .foot-text,
    .foot-text-bigger {
      text-align: left;
      padding: 20px;
      font-size: 0.675em;
      line-height: 1.3;
    }

    .foot-text-bigger {
      font-size: 0.875em;
    }
  }

  .prof-message {
    padding: 20px;

    &.danger {
      color: $color-danger;
    }

    span {
      display: block;
      line-height: 1.3;
    }

    &__large {
      font-size: 24px;
    }

    &__small {
      font-size: 1em;
    }
  }

  .tooltip-mark {
    display: inline-block;
    font-style: normal;
    width: 14px;
    height: 14px;
    background: #262262;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
    vertical-align: text-top;
    font-weight: 500;
  }
}

.prof-billing-wrap {
  max-width: 650px;
  margin: 40px auto 0;

  .pb-ittem {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-mid-grey;
    text-align: left;
    font-weight: 400;

    &:last-child {
      border: none;
    }

    h4 {
      font-size: 0.925em;
      margin-bottom: 10px;
    }
  }

  .pb-ittem-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: 0.8em;

    .content {
      span,
      img {
        display: inline-block;
        margin-right: 10px;
      }

      span {
        line-height: 1.3;
      }

      .block {
        display: block;
      }
    }

    .actions {
      flex-shrink: 0;

      a {
        display: inline-block;
        margin-right: 5px;
        color: $color-doc-brand-sec;

        &:hover {
          color: darken($color-doc-brand-sec, 7.5%);
        }
      }
    }
  }

  .pb-table {
    width: 100%;

    tr {
      border-bottom: 1px solid $color-mid-grey;

      td,
      th {
        padding: 10px 0;
      }

      th {
        color: $color-darker-grey;
      }

      a {
        color: $color-doc-brand-sec;

        &:hover {
          color: darken($color-doc-brand-sec, 7.5%);
        }
      }
    }
  }

  .row {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    .pb-form-control {
      margin-bottom: 10px;
    }

    .col-3 {
      width: 70px;
      margin-right: 10px;
    }

    .col-6 {
      width: 155px;
      margin-right: 10px;
    }
  }

  .pb-form-control {
    font: inherit;
    width: 320px;
    margin-bottom: 10px;
    border: none;
    outline: none;
    border-radius: 3px;
    height: 40px;
    background: rgba(22, 23, 56, 0.04);
    border: 1px solid rgba(22, 23, 56, 0.15);
    padding: 0 10px;
  }
}

.modal-window {
  .profile-modal {
    padding-top: 20px;

    .md-close {
      color: $color-dark-grey;
    }

    h4 {
      line-height: 1.4;

      &.warning {
        color: $color-danger;
      }

      &.message {
        text-align: center;
        font-weight: 500;
        padding: 10px 0;
      }
    }

    p {
      line-height: 1.4;
    }

    .md-footer {
      max-width: none;

      .btn-large {
        min-width: 110px;
        text-align: center;
      }
    }

    .plan-steps {
      position: relative;
      max-width: 240px;
      margin: 20px auto;
      text-align: center;
      display: flex;
      justify-content: space-between;

      &:after {
        z-index: 1;
        content: "";
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        height: 1px;
        background: $color-dark-grey;
      }

      .step {
        position: relative;
        z-index: 2;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid $color-dark-grey;
        text-align: center;
        line-height: 28px;
        font-weight: 500;
        font-size: 14px;
        color: $color-dark-grey;
        background: $color-white;

        &.active {
          background: $color-doc-brand-sec;
          border-color: $color-doc-brand-sec;
          color: $color-white;
        }
      }
    }

    .plan-header {
      font-size: 1.4em;
      color: #13bec4;
      border-bottom: 1px solid $color-doc-brand-sec;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    .plan-info {
      margin: 0;
    }

    .plan-footer {
      margin-top: 10px;
      font-weight: 500;
    }

    .plan-cc {
      p {
        margin: 0 0 5px;

        label {
          display: inline-block;
          width: 140px;
          text-align: right;
          font-weight: 500;
          margin-right: 5px;
        }

        .form-control {
          width: 45px;
          height: 24px;
          border-radius: $border-radius;
          outline: none;
          border: 1px solid $color-mid-grey;
        }
      }
    }
  }
}

.md-body {
  .PhoneInputCountry {
    margin-bottom: 15px;
  }
}

.PhoneInputCountrySelect {
  width: 40px !important;
}

.PhoneInputCountryIcon--border {
  width: 34px;
  box-shadow: none;
}

.patient-account {
  .form-group {
    margin-bottom: 10px;
    position: relative;
    .selectWrap {
      max-height: 75px;
      width: 70%;
      border: none;
      .slackSelect {
        width: 100%;
        .docto__control {
          min-width: 320px;
          background-color: rgba(22, 23, 56, 0.04);
          .docto__value-container {
            padding: 0px 8px;
            .docto__input-container {
              padding: 0px;
              height: 32px;
            }
          }
        }
      }
    }
    &.phone {
      display: flex;
      justify-content: space-around;
      width: 471px;
      margin: auto;
      margin-bottom: 10px;
      position: relative;

      .PhoneInput {
        display: flex;
        align-items: center;
        width: 320px;
      }
    }
  }
}
