.chat-module {
  z-index: 9991;
  position: fixed;
  bottom: 0px;
  right: 68px;
  width: $sidebar-in-call-width;

  &.chat-hidden {
    pointer-events: none;
    .chat-module__inner {
      display: none;
    }
    .chat-module-call-actions {
      display: flex;
    }
  }

  &__block {
    width: 100%;

    &__header {
      width: 100%;
      height: 36px;
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      padding: 0 0 0 12px;
      border-radius: 6px;
      background: $color-doc-brand-sec;
      color: $color-white;
      font-weight: 400;
      font-size: 0.875em;

      &.current {
        background: $color-danger;

        .ps-message-count {
          .user-count {
            color: $color-danger;
          }
        }
      }
      &.yellow {
        background: $color-mz-yellow;

        .ps-message-count {
          .user-count {
            color: $color-mz-yellow;
          }
        }
      }

      &.recent {
        background: $color-doc-brand-sec;

        .ps-message-count {
          .user-count {
            color: $color-doc-brand-sec;
          }
        }
      }

      &.inactive {
        background: $color-darker-grey;

        .ps-message-count {
          .user-count {
            color: $color-darker-grey;
          }
        }
      }

      &.very-inactive {
        background: $color-true-black;

        .ps-message-count {
          .user-count {
            color: $color-true-black;
          }
        }
      }

      > i {
        margin-right: 6px;
        font-size: 17px;
        margin-bottom: 1px;

        &.icon-cog-bold {
          font-size: 14px;
          cursor: pointer;
        }
      }

      span {
        font-size: 0.8em;
        text-transform: uppercase;
      }

      .patient-status {
        min-width: 0;

        .ps-truncate {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 190px;
        }
      }

      .ps-timer {
        margin: 0 10px 0 3px;
      }

      .cp-close {
        margin-right: 12px;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      .cp-minim {
        flex: 1;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
      }

      .cp-close {
        i {
          font-size: 16px;
          color: $color-white;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .ps-message-count {
        position: relative;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        font-size: 17px;

        i {
          position: absolute;
          top: 1px;
          left: 0;
          font-size: 14px;
        }

        .user-count {
          position: absolute;
          z-index: 2;
          top: -5px;
          right: -5px;
          width: 12px;
          height: 12px;
          margin: 0;
          background-color: $color-white;
          text-align: center;
          line-height: 12px;
          font-style: normal;
          font-size: 8px;
          font-weight: 500;
          border-radius: 50%;
        }
      }
    }

    &__sub-header {
      display: none;
      width: 100%;
      align-content: center;
      align-items: center;
      padding: 8px 8px;
      background: #f9f9f9;

      .client-avatar {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        background-color: $color-white;

        span {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 8px;
          width: 100%;
          height: 100%;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 20px;
        }

        &:hover {
          span {
            opacity: 1;
          }
        }
      }

      .client-info {
        padding: 0 8px;
        width: 155px;

        span {
          @include ellipsis(100%);
          display: block;
          color: $color-font-grey;
          font-size: 0.775em;
          line-height: 1.25;
          font-weight: 400;
        }
      }

      .sh-panel-trigger {
        margin-left: auto;

        a {
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          color: $color-font-grey;
          margin-left: 5px;

          &:hover {
            color: darken($color-font-grey, 12%);
            text-decoration: none;
          }
        }
      }

      .sh-actions {
        margin-left: 5px;

        a {
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          color: $color-chat-blue;
          margin-left: 5px;

          &:hover {
            color: darken($color-chat-blue, 12%);
            text-decoration: none;
          }
        }

        .cp-close {
          font-size: 14px;
          color: $color-danger;

          &:hover {
            color: darken($color-danger, 12%);
          }

          i {
            transform: rotate(90deg);
            display: block;
          }
        }
      }
    }

    &__body {
      position: relative;
      display: none;
      flex: 1;
      flex-direction: column;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      background: $color-white;
    }

    &__scroll {
      position: relative;
      height: 100%;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background: $color-white;

      &__inner {
        height: 100%;
      }

      &__push {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .chat-item {
        position: relative;
        display: block;
        padding: 8px 8px 8px 48px;

        .ci-time-stamp {
          display: block;
          font-size: 0.75em;
          margin: 0px 5px 2px 0;
        }

        .ci-avatar {
          position: absolute;
          top: 22px;
          left: 12px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-size: cover;
          background-position: center center;
        }

        .ci-text-bubble {
          padding: 8px;
          background: $color-light-grey;
          font-size: 0.9em;
          line-height: 1.2;
          font-weight: 400;
          border-radius: 6px;
          color: $color-font-grey;
        }

        &.received {
          .ci-text-bubble {
            background: $color-chat-blue;
            color: $color-white;
          }
        }

        &.attachment {
          display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
          display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
          display: -ms-flexbox; /* TWEENER - IE 10 */
          display: -webkit-flex; /* NEW - Chrome */
          display: flex;
          align-items: flex-end;
          flex-direction: column;

          img {
            width: 120px;
          }

          .ci-attch-link {
            cursor: pointer;
            padding: 10px;
            font-size: 11px;
            font-weight: 400;
            color: #444;
            text-decoration: underline;
          }
        }

        &.chat-message-info {
          .ci-message-bubble {
            padding: 8px;
            background: $color-doc-brand-sec;
            color: $color-white;
            font-size: 0.7em;
            line-height: 1.2;
            font-weight: 400;
            border-radius: 6px;
            text-align: center;
            max-width: 280px;
            margin: 0 auto;

            > i {
              display: block;
              font-size: 26px;
              margin-bottom: 4px;
            }

            span {
              display: inline-block;
              margin-bottom: 8px;
            }

            &:after {
              display: none;
            }
          }

          .ci-message-actions {
            display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox; /* TWEENER - IE 10 */
            display: -webkit-flex; /* NEW - Chrome */
            display: flex;
            justify-content: center;
            flex-shrink: 1;

            .btn-chat {
              flex: 1;
              background: #fff;
              padding: 0 1px;
              line-height: 24px;
              min-height: 24px;
              height: 24px;
              font-size: 9px;
              font-weight: 500;
              margin-left: 5px;
              color: $color-grey !important;

              &:first-child {
                margin-left: 0;
              }

              i {
                line-height: 24px;
                height: 24px;
                min-height: 24px;
                font-size: 13px;
                margin-right: 2px;
              }
            }
          }
        }
      }
    }

    &__switch-btn {
      display: block;
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      height: 22px;
      width: auto;
      font-size: 10px;
      line-height: 22px;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid $color-white;
      margin-right: 5px;

      &:hover {
        background-color: $color-white;
        color: $color-danger;
      }
    }

    &__chat-box {
      position: relative;
      display: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 1px solid #ccc;

      .status-message {
        position: absolute;
        top: -21px;
        left: 0;
        width: 100%;
        height: 20px;
        padding: 0 5px;
        font-size: 11px;
        line-height: 20px;
        color: $color-grey;
        font-weight: 400;
        background: rgba($color-white, 0.9);
      }

      pre,
      .cb-textbox {
        background: transparent;
        white-space: pre;
        white-space: pre-wrap;
        word-wrap: break-word;
        padding: 8px 12px 30px;
        font-size: 0.9em;
        font-weight: 400;
        line-height: 1.2;
        font-family: inherit;
      }

      pre {
        min-height: 68px;
        max-height: 98px;
        visibility: hidden;
      }

      .cb-textbox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        border: none;
        resize: none;
        color: $color-grey;
        background: $color-white;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: text;

        &:focus {
          outline: none;
          background: #f2f9ff;
          color: darken($color-grey, 12%);
        }

        &:focus + .cb-actions {
          background: #f2f9ff;
        }
      }

      .cb-submit-message {
        display: none;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 40px;
        text-align: center;
        font-size: 20px;
        color: $color-doc-brand;
        text-decoration: none;
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $color-grey;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: $color-grey;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: $color-grey;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: $color-grey;
      }

      .cb-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 4px 12px 4px;
        background: $color-white;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        a {
          font-size: 20px;
          color: $color-grey;

          &:hover {
            text-decoration: none;
            color: $color-chat-blue;
          }
        }

        .emoji-toggle {
          @media only screen and (max-width: 480px) {
            display: none;
          }
        }
      }
    }

    &.active {
      .chat-module__block__sub-header {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex;
      }

      .chat-module__block__body {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex;
      }
      .chat-module__block__chat-box {
        display: block;
      }

      .chat-module__block__header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .chat-module__block__body {
        height: 250px;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }

      .ps-message-count {
        display: none;
      }
    }
  }
}

.chat-emoji-wrap {
  pointer-events: none;
  perspective: 2000px;
  position: absolute;
  z-index: 99999999;
  position: absolute;
  bottom: 40px;
  right: calc(50% - 130px);

  .chat-emoji-popover {
    height: auto;
    width: 260px;
    background-color: $color-white;
    border-radius: 6px;
    transform: scale(0.75);
    transform-origin: 260px 100%;
    opacity: 0;
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 0.25s;

    &:after {
      content: "";
      position: absolute;
      bottom: -7px;
      right: 8px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $color-white;
    }
  }

  &.active {
    .chat-emoji-popover {
      transform: none;
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.md-call-setup {
  z-index: 9999999;

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  .modal {
    padding: 0;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 330px;
    border: none;
    border-radius: 8px;
    background: #fafafa;
    color: #525252;

    @media screen and (max-width: 480px) {
      margin-top: 0;
      margin-bottom: 0;
      max-width: none;
      border-radius: 0;
      min-height: 100vh;
    }
  }

  .md-header {
    position: relative;
    height: 40px;
    padding: 0;
    background: $color-doc-brand-sec;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;

    @media screen and (max-width: 480px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    span {
      color: $color-white;
      font-weight: 300;
      line-height: 40px;
      font-size: 13px;
    }

    .md-close {
      top: 10px;
      right: 12px;
      color: $color-white;
      font-size: 18px;
    }
  }

  .setup-body {
    min-height: 180px;
    padding-bottom: 30px;

    .sb-loading {
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      padding: 50px 0;

      .spinner {
        width: 60px;
        height: 60px;
        padding: 0px;
        margin: 0px;

        .logo-spin {
          width: 60px;
          height: 60px;
        }
      }

      p {
        margin: 20px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: $color-darker-grey;
      }
    }

    .preview-video {
      margin: 20px auto 0;
      background: $color-true-black;
      width: 264px;
      height: 198px;

      div {
        margin: 0 auto;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        min-height: 198px;
        margin-top: 0;
      }
    }

    .preview-audio {
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 10px;
      align-content: center;
      align-items: center;

      i {
        font-size: 20px;
        color: $color-darker-grey;
        margin-right: 5px;
      }

      em {
        width: 8px;
        height: 14px;
        margin: 0 1px;
        background-color: transparent;
        border: 1px solid $color-mid-grey;
        border-radius: 4px;

        &.active {
          background-color: $color-mid-grey;
        }
      }
    }

    .preview-settings {
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0 8px;
      margin-top: 20px;

      .form-group {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 25px;

        label {
          display: block;
          width: 100%;
          margin: 0 0 10px 0;
          padding: 0;
          text-align: center;
          font-weight: 300;
          font-size: 16px;
          color: $color-darker-grey;
        }

        select,
        .btn {
          width: 100%;
          height: 34px;
          border-radius: 3px;
          font-weight: 400;
          font-size: 11px;
        }

        select {
          padding: 0 22px 0 8px;
          -webkit-appearance: none;
          border: 1px solid $color-mid-grey;
          color: $color-darker-grey;
          background-color: transparent;
          background-image: url("../../assets/images/select-double-caret.png");
          background-position: 94% center;
          background-size: 12px;
          background-repeat: no-repeat;
        }

        .btn {
          padding: 0;
          line-height: 34px;
          background: $color-doc-brand-sec;
          border-color: $color-doc-brand-sec;
          color: $color-white;
          cursor: pointer;

          &:hover {
            background: darken($color-doc-brand-sec, 4%);
            border-color: darken($color-doc-brand-sec, 4%);
          }

          i {
            margin-right: 5px;
          }
        }
      }
    }

    .preview-message {
      padding: 0 20px;
      font-size: 11px;
      text-align: center;
      font-weight: 400;
      line-height: 1.4;
    }

    .preview-footer {
      .btn {
        display: block;
        height: 30px;
        width: 120px;
        margin: 20px auto 0;
        padding: 0;
        line-height: 28px;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        border-radius: 4px;
        color: $color-white;
        background-color: $color-doc-brand;

        &:hover {
          background-color: darken($color-doc-brand, 5%);
          text-decoration: none;
        }
      }
    }
  }
}

.md-call-setup-inner {
  top: 100px;
  left: 20px;
  position: absolute;
  z-index: 9999;

  .md-setup-close {
    z-index: 99;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 0.675em;
    padding: 5px 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $color-darker-grey;
    color: $color-white;
  }

  .md-body {
    width: 100%;
    max-width: 320px;
    padding-bottom: 5px;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 6px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.45);
    border: 1px solid hsla(0, 0%, 100%, 0.1);
  }

  @media screen and (max-width: 480px) {
    left: 10px;
    right: 10px;
    top: 60px;

    .md-body {
      max-width: 100%;
      border-radius: 0;
      border: none;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .jag-call-setup {
      padding: 10px 20px;
    }

    .md-setup-close {
      text-align: center;
    }
  }
}

.md-device-select {
  width: 45%;
  border: 1px solid rgb(255 255 255 / 55%);
  background: rgb(255 255 255 / 75%);
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 2px;
}

.md-copy-url {
  .modal {
    padding: 30px 20px;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 380px;
    border: none;
    border-radius: 8px;
    background: #fff;
    color: #525252;

    .md-close {
      top: 10px;
      right: 10px;
      color: inherit;
      font-size: 18px;
    }

    .md-head {
      height: auto;
      color: inherit;
      margin-bottom: 15px;

      h1 {
        font-size: 1.5em;
        margin-bottom: 5px;
      }

      p {
        font-size: 0.8em;
        margin-bottom: 0;
        line-height: 1.25;
        font-weight: 400;
      }
    }

    .md-body {
      p {
        margin-bottom: 3px;
        font-size: 0.8em;
      }

      .md-copy-url-wrap {
        display: flex;
        align-content: center;
        align-items: center;

        input {
          flex: 1;
          border: 1px solid $color-mid-grey;
          border-right: none;
          padding: 10px 15px;
          height: auto;
          line-height: 1;
          font-size: 0.8em;
        }

        button {
          flex: 0 0 auto;
          padding: 10px 15px;
          background: $color-doc-brand-sec;
          border: 1px solid $color-doc-brand-sec;
          color: $color-white;
          font-size: 0.8em;

          &:hover {
            background: darken($color-doc-brand-sec, 3%);
            border-color: darken($color-doc-brand-sec, 3%);
          }
        }
      }
    }
  }
}

.emoji-mart-scroll {
  height: 200px !important;
}

.emoji-mart-category-label span {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.emoji-mart {
  -webkit-box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.2);
}

.emoji-mart .emoji-mart-emoji {
  padding: 5px !important;
}

@media screen and (max-width: 480px) {
  .chat-active {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    z-index: 3;
  }

  .chat-module {
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0px;
    height: auto;
    max-height: 100vh;
    border-radius: 6px;
    background: $color-white;
    &.chat-hidden {
      pointer-events: none;
      background: none;
    }

    &:before {
      display: none;
    }

    &__inner {
      height: calc(100%);
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex;
      flex-direction: column;
    }

    &__block {
      position: relative;
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex;
      flex-direction: column;

      &.active {
        flex: 1;
      }

      &__header {
        height: 42px;

        > i {
          font-size: 22px;
        }

        .ps-message-count {
          width: 22px;
          height: 22px;

          i {
            font-size: 20px;
          }
        }

        > i.icon-cog-bold {
          font-size: 20px;
          margin-right: 8px;
        }

        .cp-close {
          i {
            font-size: 20px;
          }
        }

        .cp-close {
          margin-right: 15px;
        }
      }

      &__sub-header {
        .sh-actions,
        .sh-panel-trigger {
          a,
          .cp-close {
            font-size: 22px;
            line-height: 22px;
            margin-left: 10px;
          }
        }
      }

      &__body {
        position: absolute;
        top: 104px;
        bottom: 85px;
        height: auto !important;
        left: 0;
        right: 0;
        //display: block;
      }

      &__chat-box {
        //display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        pre,
        .cb-textbox {
          font-size: 16px;
          padding-right: 50px;
        }

        .cb-submit-message {
          display: block;
        }
      }

      &__switch-btn {
        display: block;
      }
    }
  }
}

.chat-module-call-actions {
  pointer-events: none;
  position: fixed;
  z-index: 1;
  top: 0;
  height: 100%;
  right: 0;
  display: none;
  justify-content: center;
  flex-direction: column;

  button {
    position: relative;
    pointer-events: all;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 10px;
    background-color: $color-doc-brand-sec;
    color: $color-white;
    font-size: 22px;

    &.button-blue {
      background-color: $color-doc-brand;
      color: $color-white;
    }
    &.button-white {
      background-color: $color-white;
      color: $color-true-black;
    }
    &.button-transparent {
      background-color: rgba(255, 255, 255, 0.75);
      color: $color-true-black;
      &:hover {
        background: rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
    &.button-yellow {
      background-color: $color-mz-yellow;
      color: $color-white;
    }

    span {
      font-size: 22px;
      text-transform: uppercase;
    }

    em {
      position: absolute;
      z-index: 10;
      top: -5px;
      right: 0;
      display: flex;
      width: 16px;
      height: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 10px;

      background-color: $color-danger;
      color: $color-white;
    }

    .img-wrap {
      position: absolute;
      z-index: 1;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background-size: cover;
      background-position: center center;
    }
  }
}

.vid-body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    position: relative;
    height: 100%;
  }
  video {
    object-fit: contain !important;
  }

  .vid-body-message {
    position: absolute;
    z-index: 0;
    text-align: center;
    top: 50%;
    width: 100%;
    padding: 0 !important;

    span {
      padding: 14px 20px;
      background-color: $color-white;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .pannel-full-right,
  .pannel-full-left,
  .pannel-top-right,
  .pannel-top-left,
  .pannel-bottom-right,
  .pannel-bottom-left,
  .pannel-main {
    .notes-pannel {
      .notes-pannel-inner {
        display: none;
      }

      &.active {
        .notes-pannel-inner {
          display: block;
          height: calc(100% - 20px);
        }
      }

      .patient-pannel {
        form {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) {
  .side-bar-active {
    .chat-module {
      top: $top-bar-height-in-call;
      right: 0;

      &.ac-full-screen {
        width: 100%;
      }

      .chat-module__block__header {
        border-radius: 0;
      }

      .chat-module__inner {
        height: 100%;
      }

      .chat-module__block {
        height: 100%;

        .chat-module__block__body {
          height: calc(100% - 155px);

          .chat-module__block__scroll__inner {
            height: 100%;
          }
        }
      }

      .cb-actions,
      .cb-textbox {
        border-radius: 0;
      }
    }
  }

  .pannel-main {
    .chat-wrap,
    .chat-module,
    .appointment-chat-box-wrap,
    .notes-pannel,
    .vid-box-float.vid-full {
      left: 0;
      right: 0;
      bottom: 0;

      .notes-pannel-inner {
        width: 100% !important;
      }
    }

    .meeting-controls {
      left: 0;
      right: 0;
    }
  }

  .sba-right {
    .pannel-main {
      .chat-wrap,
      .chat-module,
      .appointment-chat-box-wrap,
      .notes-pannel,
      .vid-box-float.vid-full,
      .doc-builder-module {
        right: $sidebar-in-call-width;
        width: auto;
        bottom: 0;
      }

      .vid-box-float.vid-sec {
        right: $sidebar-in-call-width;
        bottom: initial;
        top: 40px;
        width: 120px;
        height: 70px;
      }

      .meeting-controls {
        right: $sidebar-in-call-width;
      }
    }
  }

  .sba-left {
    .pannel-main {
      .chat-wrap,
      .chat-module,
      .appointment-chat-box-wrap,
      .notes-pannel,
      .vid-box-float.vid-full,
      .doc-builder-module {
        left: $sidebar-in-call-width;
        width: auto;
        bottom: 0;
      }

      .vid-box-float.vid-sec {
        left: $sidebar-in-call-width;
        bottom: initial;
        top: 40px;
        width: 120px;
        height: 70px;
      }

      .meeting-controls {
        left: $sidebar-in-call-width;
      }

      .md-call-setup-inner {
        left: $sidebar-in-call-width;
        margin-left: 20px;
      }
    }
  }

  .pannel-full-right,
  .pannel-full-left,
  .pannel-top-right,
  .pannel-top-left,
  .pannel-bottom-right,
  .pannel-bottom-left,
  .pannel-main {
    .notes-pannel {
      height: auto;
    }
  }

  .pannel-full-right,
  .pannel-full-left,
  .pannel-top-right,
  .pannel-top-left,
  .pannel-bottom-right,
  .pannel-bottom-left {
    .notes-pannel {
      display: none;

      &.active {
        display: block;
      }
    }

    .meeting-holder {
      .video-box-panel {
        position: initial;

        .vid-box-float.vid-full {
          position: absolute;
          top: 0;
        }

        .vid-box-float.vid-sec {
          display: none;
        }
      }
    }

    .mc-btn {
      display: none;
    }
  }

  .pannel-full-right {
    .chat-module,
    .meeting-holder,
    .notes-pannel {
      top: $top-bar-height-in-call;
      left: initial;
      right: 0;
      bottom: 0;
      width: $sidebar-in-call-width;
    }
  }

  .pannel-full-left {
    .meeting-holder,
    .notes-pannel,
    .chat-module {
      top: $top-bar-height-in-call;
      left: 0;
      right: initial;
      bottom: 0;
      width: $sidebar-in-call-width;
    }
  }

  .pannel-top-right {
    .notes-pannel,
    .meeting-holder,
    .chat-module {
      top: $top-bar-height-in-call;
      left: initial;
      right: 0;
      bottom: 50%;
      width: $sidebar-in-call-width;
    }
  }

  .pannel-top-left {
    .notes-pannel,
    .meeting-holder,
    .chat-module {
      top: $top-bar-height-in-call;
      left: 0;
      right: initial;
      bottom: 50%;
      width: $sidebar-in-call-width;
    }
  }

  .pannel-bottom-right {
    .notes-pannel,
    .meeting-holder,
    .chat-module {
      top: 50%;
      left: initial;
      right: 0;
      bottom: 0;
      width: $sidebar-in-call-width;
    }
  }

  .pannel-bottom-left {
    .notes-pannel,
    .meeting-holder,
    .chat-module {
      top: 50%;
      left: 0;
      right: initial;
      bottom: 0;
      width: $sidebar-in-call-width;
    }
  }
}

.meeting-container {
  .pannel-full-right {
    .meeting-holder,
    .notes-pannel {
      top: $top-bar-height;
    }
  }

  .pannel-full-left {
    .meeting-holder,
    .notes-pannel,
    .chat-module {
      top: $top-bar-height;
    }
  }

  .pannel-top-right {
    .notes-pannel,
    .meeting-holder,
    .chat-module {
      top: $top-bar-height;
    }
  }

  .pannel-top-left {
    .notes-pannel,
    .meeting-holder,
    .chat-module {
      top: $top-bar-height;
    }
  }
}
