@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

$font-light: 300;
$font-normal: 400;
$font-medium: 600;
$font-bold: 700;

html,
body {
  font-family: "Lexend", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $color-font;
}

h1,
h2,
h3,
h4,
h5,
b,
strong,
.bold,
.strong {
  font-family: "Lexend", sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 1.875em;
}

h2 {
  font-size: 1.625em;
}

h3 {
  font-size: 1.375em;
}

h4 {
  font-size: 1.125em;
}

a {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.link-muted {
    color: $color-dark-grey;

    &:hover {
      color: darken($color-dark-grey, 5%);
    }
  }
}
