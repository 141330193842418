.error-page {
  min-height: calc(100vh - 60px);
  width: 100%;
  padding-top: 80px;
  padding-bottom: 100px;
  background-image: url(../../assets/images/error-page.jpg);
  background-position: center bottom;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    color: #fff;

    &.large {
      font-size: 160%;
    }
  }

  .left-side {
    text-align: right;
    font-size: 75px;
    line-height: 70px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .right-side {
    position: relative;
    line-height: 1.4;
    margin-left: 30px;

    a {
      color: #fff;
      text-decoration: underline;
      font-weight: 300;
    }

    &:before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -15px;
      background: #fff;
    }
  }
}
