$color-available: #272264;
$color-selected: #336cfb;
$color-disabled: #b1afaf;

.booking-calendar {
  margin-top: 10px;
  background-color: transparent !important;
  font-size: 1.12em;
  border: none !important;
}
.booking-calendar .react-datepicker__header {
  padding-top: 0.8em;
  background-color: transparent !important;
  border: none !important;
}
.booking-calendar
  .react-datepicker__header--custom
  .datepicker-header
  .btn-holder {
  width: 30px;
  display: flex;
  justify-content: inherit;
}
.booking-calendar .react-datepicker__header--custom .datepicker-header {
  margin: 22px;
  display: flex;
  justify-content: space-between;
}
.booking-calendar .react-datepicker__current-month {
  font-weight: bold;
}
.booking-calendar .react-datepicker__month {
  font-weight: lighter;
}
.booking-calendar .react-datepicker__day-name,
.booking-calendar .react-datepicker__day {
  width: 30px;
  line-height: 30px;
  margin: 0.166em;
  border-radius: 15px;
  color: $color-available;
  font-weight: 400;
}
.booking-calendar .react-datepicker__day:focus {
  outline: none;
}
.booking-calendar .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}
.booking-calendar .react-datepicker__day:hover {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $color-disabled;
  color: white;
  border: none;
}
.booking-calendar .react-datepicker__day--highlighted {
  background-color: unset;
}
.booking-calendar .react-datepicker__day--highlighted::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: $color-available;
  border-radius: 2.5px;
  z-index: 90;
  display: block;
  margin-left: 45%;
  margin-top: -20%;
}
.booking-calendar
  .react-datepicker__day--selected.react-datepicker__day--highlighted::after {
  background-color: white;
}
.booking-calendar .react-datepicker__day--disabled,
.booking-calendar .react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: $color-disabled;
}
.booking-calendar .react-datepicker__day--selected {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $color-selected;
  color: white;
}
.booking-calendar .react-datepicker__day--selected:hover {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $color-selected;
  color: white;
  border: none;
}
.booking-calendar .react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.booking-calendar .react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.booking-calendar .react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.app-step-result {
  text-align: center;
  margin-top: 20px;
  color: #272264;
  font-size: smaller;
  width: 225px;
}
