$icms-width: 380px;
$icms-border-rad: 5px;
$icms-padding: 15px;

$icms-color-background: $color-white;
$icms-color-mid-grey: #95a0aa;
$icms-color-border-grey: #e4eced;
$icms-color-dark-blue: #273d52;
$icms-color-light-grey: #b8b8b8;
$icms-color-text: #3c5164;

.icms-mt10 {
  margin-top: 10px;
}
.icms-mt20 {
  margin-top: 20px;
}
.icms-mt30 {
  margin-top: 30px;
}
.icms-mt40 {
  margin-top: 40px;
}

.meeting-sidebar {
  z-index: 10;
  position: absolute;
  top: 80px;
  right: (-$icms-width);
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: $icms-width;
  width: 100%;
  background: $icms-color-background;
  color: $icms-color-text;
  transition: right 0.25s linear;

  &.icms-show {
    right: 0;
    transition: right 0.25s linear;
  }
}

.icms-scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $icms-padding;
}

.icms-top-actions {
  display: flex;
  justify-content: space-between;
  padding: calc(#{$icms-padding} / 2) 0;
  margin-bottom: $icms-padding;

  .icms-top-link {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-transform: uppercase;
    color: $icms-color-mid-grey;
    line-height: 20px;

    &:hover {
      text-decoration: none;
      color: darken($icms-color-mid-grey, 10%);
    }

    i {
      font-size: 12px;
      margin: 0 3px;
      line-height: 20px;
    }
  }
}

.icms-pannel {
  background: $icms-color-background;
  border-radius: $icms-border-rad;
  border: 1px solid $icms-color-border-grey;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.075);
  padding: $icms-padding;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 0.875em;
  color: $icms-color-text;
}

.icms-label {
  display: block;
  font-size: 0.875em;
  font-weight: 600;
  margin-bottom: calc(#{$icms-padding} / 2);
  padding: 0 calc(#{$icms-padding} / 2);
  color: $icms-color-dark-blue;
}

.icms-label-with-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .icms-label {
    margin: 0;
  }
}

.icms-btn {
  display: inline-block;
  height: 40px;
  padding: 0 12px;
  margin: 0;
  text-align: center;
  border-radius: 5px;
  background-color: $color-doc-brand-sec;
  line-height: 40px;
  font-weight: 400;
  font-size: 0.875em;
  color: $color-white;
  text-transform: uppercase;

  &:hover {
    background-color: darken($color-doc-brand-sec, 5%);
    color: $color-white;
    text-decoration: none;
  }
}

textarea,
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
select[multiple="multiple"] {
  &.icms-form-control {
    background: $icms-color-background;
    border-radius: $icms-border-rad;
    border: 1px solid $icms-color-border-grey;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.075);
    padding: $icms-padding;
    font-weight: 400;
    line-height: 1;
    font-size: 0.875em;
    color: $icms-color-text;

    &:focus {
      outline: none;
      border: 1px solid $color-doc-brand-sec;
    }
  }
}

.icms-form-group {
  display: flex;
  margin-bottom: 10px;

  &.force-wrap {
    flex-wrap: wrap;
  }

  .icms-form-control {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  &.with-right-icon {
    position: relative;

    .icms-form-control {
      padding-right: 50px;
    }

    i {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 48px;
      line-height: 50px;
      font-size: 22px;
      text-align: center;
      color: $icms-color-light-grey;
    }
  }
}

.icms-form-note {
  flex: 0 0 100%;
  font-weight: 300;
  font-size: 0.825em;
  margin-top: 5px;
  padding: 0 5px;
}

.icms-footer {
  margin-top: auto;
  width: 100%;

  .icms-click {
    display: block;
    width: 100%;
    padding: 10px;
    background: $color-doc-brand-sec;
    color: $color-white;
    text-align: center;
    font-size: 1em;
    line-height: 1;

    &:hover {
      background: darken($color-doc-brand-sec, 5%);
    }
  }
}

.icms-timepicker {
  position: relative;
  padding: 4px 48px 4px 15px;
  min-height: 48px;

  .icms-timepicker-toggle {
    position: absolute;
    top: 0;
    right: 0;
    height: 48px;
    width: 50px;
    text-align: center;

    i {
      line-height: 48px;
      font-size: 14px;
    }
  }

  .icms-timepicker-row {
    display: none;

    &.active {
      display: flex;
    }
  }

  .icms-timepicker-btn {
    height: 30px;
    width: 65px;
    padding: 0 5px;
    margin: 4px;
    text-align: center;
    border-radius: $icms-border-rad;
    background-color: transparent;
    line-height: 30px;
    font-weight: 400;
    font-size: 0.875em;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: $color-doc-brand-sec;
      color: $color-white;
      text-decoration: none;
    }
  }
}
