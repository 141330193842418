.landing-page {
  .row-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .text-right {
    text-align: right;
  }

  p {
    line-height: 1.4;
  }

  .no-results {
    text-align: center;
    color: #262262;
    display: table-cell;
    vertical-align: middle;
    padding: 75px 0;

    i {
      font-size: 70px;
      display: inline-block;
      vertical-align: middle;
    }

    p {
      font-size: 1.25em;
      line-height: 1.3;
      text-transform: uppercase;
      margin-top: 15px;
    }
  }

  .genesis-nav-menu > .menu-item > a.logout-landing {
    padding: 0 15px !important;
    background-color: #aaaaac;
    color: #fff;
    text-transform: uppercase;
    line-height: 32px;
    font-weight: 300;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
      background-color: #9c9c9e;
    }
  }

  .lndng-hero {
    margin-top: 80px;
    width: 100%;
    min-height: 300px;
    padding: 80px 0;
    background: url(../../assets/images/home-hero-doctor-02.jpg);
    background-size: cover;
    background-position: center bottom;

    @media screen and (min-width: 1200px) {
      .container {
        max-width: 1400px;
        width: 100%;
      }
    }

    .home-primary-actions {
      text-align: center;

      h1,
      h2 {
        margin-bottom: 40px;
        margin-top: 0;
        color: $color-white;
        line-height: 1.3;
        font-size: 2.2em;
        font-weight: 400;
        text-align: center;
      }

      [class^="btn-"],
      [class*="btn-"] {
        display: block;
        width: 100%;
        text-transform: uppercase;
        max-width: 375px;
        margin: 0 auto 15px;
        line-height: 50px;
        font-size: 18px;
        border-radius: 4px;
      }
    }

    .home-hero {
      position: relative;
      text-align: center;
      padding: 50px 0;
      width: 650px;
      margin: 0 auto;

      .hero-badge {
        position: absolute;
        top: 50%;
        right: -50px;
        margin-top: -60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 120px;
        height: 120px;
        background: #f99224;
        border-radius: 60px;

        span {
          text-transform: uppercase;
          color: #ffffff;
          font-weight: 700;
          font-size: 13px;
          line-height: 1.3;
          text-align: center;
        }
      }

      h1 {
        margin-top: 50px;
        margin-bottom: 50px;
        line-height: 1.1;
      }

      h2 {
        color: $color-white;
        font-weight: 500;
        margin-bottom: 35px;
      }

      h3 {
        line-height: 1.2;
        margin-bottom: 30px;
      }

      .hidden--palm {
        color: #fff;
        margin-bottom: 0;
      }

      .home-hero-actions {
        display: flex;
        justify-content: center;

        .btn {
          position: relative;
          width: 350px;
          margin: 0 5px 10px;

          &.disabled {
            background: #b7b7b7;
            border-color: #b7b7b7;
            cursor: not-allowed;
          }

          &:after {
            position: absolute;
            content: attr(data-label);
            top: 100%;
            left: 0;
            margin-top: 7px;
            font-weight: 400;
            font-size: 0.78em;
            color: #fff;
            text-transform: none;
            line-height: 1.4;
          }
        }
      }

      &.hero-with-btn {
        padding: 80px 0;

        h2 {
          line-height: 1.3;
          margin: 0;
          font-weight: 300;
        }

        .header-actions {
          margin-top: 30px;

          a {
            margin: 0 5px;
          }

          a.red-counter {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            background: #be2b20;
            font-weight: 400;
            font-style: 14px;
            color: $color-white;
            border-radius: 5px;

            &:hover {
              background: #a71d13;
            }
          }

          a.img-link {
            img {
              height: 40px;
            }
          }
        }
      }
    }
  }

  .home-oh-hero {
    background: url(../../assets/images/Docto_Speacialists_HeaderBanner.png);
  }

  .home-oh-24-hero {
    background: url(../../assets/images/Docto_Home_HeaderBanner.jpg);
  }

  .home-medical-telehealth {
    background: url(../../assets/images/optimized/Docto_Covid-banner-small.webp);
  }

  .home-oh-hero,
  .home-oh-24-hero,
  .home-medical-telehealth {
    margin-top: 130px;
    width: 100%;
    min-height: 300px;
    padding: 100px 0 120px;
    background-size: cover;
    background-position: center top;
    color: $color-white;
    text-align: center;

    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }

    h1 {
      font-size: 3.5em;
      font-weight: 600;
      color: $color-white;
      margin-bottom: 0;
      line-height: 1.2;
    }

    p {
      font-size: 1.75em;
      font-weight: 300;
      margin-bottom: 15px;
    }

    .mo-hero-actions {
      padding: 30px;
      display: flex;
      margin: auto;
      width: 70%;
      justify-content: center;
      font-size: 20px;
      @media (max-width: 768px) {
        padding: 0px;
        width: 80%;
        flex-direction: column;
        .items-center {
          margin-bottom: 15px;
        }
        .direction {
          margin-top: 5px;
          font-size: 00.7em;
        }
        .btn {
          width: 100% !important;
        }
      }
      .direction {
        margin-top: 10px;
        font-size: 00.7em;
      }
      .btn {
        font-size: 0.7em;
        margin: 0 15px;
        border-radius: 3px;
        background: rgba($color-doc-brand-sec, 0.85);
        color: $color-white;
        border: none !important;
        width: 150px;
        span {
          height: 10px;
          line-height: 10px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 15px;
        }

        &:hover {
          background: $color-doc-brand-sec;
        }

        &.btn-red {
          background: rgba($color-danger, 0.85);
          color: $color-white;

          &:hover {
            background: $color-danger;
          }
        }

        &.btn-blue {
          background: rgba($color-doc-brand, 0.85);
          color: $color-white;

          &:hover {
            background: $color-doc-brand;
          }
        }
        &.btn-teal {
          background: rgba($color-doc-brand-sec, 0.85);
          color: $color-white;

          &:hover {
            background: $color-doc-brand-sec;
          }
        }
      }
    }
  }

  .mo-doctor-list-wrap {
    background-color: $color-white;
    padding: 50px 0 30px;
    text-align: center;

    p {
      font-size: 1.75em;
      font-weight: 400;
      text-align: center;
      color: $color-true-black;
    }

    .btn {
      font-size: 0.7em;
      margin: 25px 15px 0;
      padding: 12px 30px;
      border-radius: 3px;
      background: rgba(19, 190, 196, 0.85);
      color: #fff;
      border: none !important;
    }

    .mo-list-holder {
      display: flex;
      justify-content: center;
      padding: 0 20px;
      flex-wrap: wrap;
      max-height: 200px;
      overflow: hidden;

      &.show-all {
        max-height: none;
        overflow: visible;
      }

      .mo-image-ittem {
        margin: 0 20px 20px;

        .mo-image {
          width: 62px;
          height: 62px;
          margin: 0 auto;
          border-radius: 50%;
          background-color: #ccc;
          background-image: url(../../assets/images/optimized/patient_placeholder.webp);
          background-size: cover;
          background-position: center center;
        }

        .mo-type {
          padding: 5px 5px 0;
          text-align: center;

          span {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.725em;
            color: $color-mute-grey;
          }
        }
      }
    }
  }

  .mo-qld-wrap {
    padding: 15px 20px;
    background-color: $color-true-black;

    .row {
      flex-wrap: nowrap;
    }

    .xadvance-logo {
      width: 100%;
      max-width: 410px;
    }

    .xadvance-text {
      display: flex;
      align-items: center;
      flex: 0 0 50%;

      span {
        color: $color-white;
      }

      span.large {
        font-size: 55px;
        font-weight: bold;
        margin-right: 15px;
        margin-left: 30px;
      }

      span.small {
        font-size: 18px;
        font-weight: normal;
        line-height: 1.2;
      }
    }

    @media screen and (max-width: 1024px) {
      .row {
        flex-direction: column;
      }

      .xadvance-text {
        max-width: 550px;
        margin-top: 15px;

        span.large {
          margin-left: 0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding: 30px 20px;

      .xadvance-text {
        flex-direction: column;
      }
    }
  }

  .mo-register-wrap {
    background: rgba(26, 134, 161, 1);
    background: linear-gradient(
      -90deg,
      rgb(79, 66, 109) 25%,
      rgba(26, 134, 161, 1) 100%
    );
    padding: 20px;

    .row {
      align-items: center;
      flex-wrap: nowrap;
    }

    p {
      margin: 0;
      color: $color-white;
      font-weight: normal;
      font-size: 26px;
    }

    .mo-register-comp {
      display: flex;
      align-items: center;
      margin-left: 25px;

      span {
        font-size: 12px;
        color: $color-white;
        font-weight: lighter;
      }

      .mo-register-comp-inner {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-left: 5px;

        ::-webkit-input-placeholder {
          /* Edge */
          color: rgba($color-white, 0.3);
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba($color-white, 0.3);
        }

        ::placeholder {
          color: rgba($color-white, 0.3);
        }

        input[type="text"] {
          background-color: rgba(0, 0, 0, 0.35);
          border: none;
          padding: 0 10px;
          height: 40px;
          margin: 0;
          color: $color-white;
          font-size: 0.875em;
          font-weight: lighter;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        button {
          height: 40px;
          padding: 0 30px;
          text-transform: uppercase;
          background-color: $color-danger-flat;
          color: $color-white;
          font-size: 0.875em;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    @media screen and (max-width: 990px) {
      padding: 40px 20px;

      .row {
        flex-direction: column;
      }

      p {
        text-align: center;
      }

      .mo-register-comp {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }

  .mo-medicare-search-wrap {
    width: 100%;
    padding: 120px 0;
    background: url(../../assets/images/optimized/Docto_Home_MedicareBanner.webp);
    background-size: cover;
    background-position: center bottom;
    color: $color-white;

    img.medicare-logo {
      width: 280px;
      display: block;
      margin: 0 auto;
    }

    p {
      font-size: 1.65em;
      text-align: left;
      font-weight: 400;
      margin-bottom: 10px;

      span {
        font-weight: bold;
        color: $color-doc-brand-sec;
      }

      @media screen and (max-width: 990px) {
        text-align: center;
      }

      @media screen and (max-width: 767px) {
        text-align: center;
        font-size: 1.2em;
        margin-top: 20px;
      }
    }

    .dropdown-toggle {
      max-width: 320px;
      margin: 0;
    }

    @media screen and (max-width: 990px) {
      text-align: center;

      img.medicare-logo {
        margin-bottom: 10px;
      }
    }
  }

  .mo-why-docto-wrap {
    width: 100%;
    padding: 120px 0;
    background: url(../../assets/images/optimized/why-docto-baner.webp);
    background-size: cover;
    background-position: center bottom;
    color: $color-white;

    .mo-wd-header {
      font-weight: 300;
      color: $color-white;

      span {
        display: block;
        font-size: 1.1em;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      h2 {
        color: $color-white;
        font-size: 3.5em;
      }
    }

    .mo-wd-list {
      padding: 0;
      margin: 30px 0 0;

      li {
        position: relative;
        list-style: circle;
        display: inline-block;
        font-weight: 300;
        color: $color-white;
        font-size: 1.1em;
        margin-bottom: 8px;

        span {
          display: inline-block;
          padding-left: 16px;
        }

        &:before {
          position: absolute;
          top: 50%;
          left: 15px;
          margin-top: -3px;
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $color-white;
          margin-right: 5px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      text-align: center;

      .mo-wd-list {
        li:before {
          display: none;

          span {
            padding-left: 0;
          }
        }
      }
    }
  }

  .foc-wrap {
    padding: 120px 0;
    background: $color-lighter-grey;
    color: $color-dark-grey;

    h3 {
      color: $color-dark-grey;
      text-align: left;
      line-height: 1.2;
      margin: 0;
      font-size: 3em;
      font-weight: 400;

      @media screen and (max-width: 990px) {
        text-align: center;
      }
    }

    h3.small {
      font-size: 1em;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .foc-av-rationg-wrap {
      display: flex;
      align-items: flex-start;
      margin-top: 40px;

      @media screen and (max-width: 990px) {
        justify-content: center;
      }

      .avr-left {
        flex: 0 0 140px;
        text-align: center;

        span {
          display: block;
          font-size: 0.9em;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .avr-num {
          font-size: 3em;
          font-weight: 800;
          margin-bottom: 5px;
          color: $color-true-black;
        }

        .avr-start-wrap {
          text-align: center;
          margin-bottom: 10px;

          i {
            display: inline-block;
            margin: 0 3px;
            color: $color-doc-brand-sec;
            font-size: 18px;
          }
        }

        .avr-total {
          i {
            margin-right: 2px;
          }
        }
      }

      .avr-right {
        flex: 1;
        max-width: 180px;

        .avr-level-wrap {
          padding-left: 20px;
          padding-top: 30px;
        }

        .avr-level {
          height: 20px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: flex-start;
          color: $color-true-black;

          i {
            display: inline-block;
            line-height: 20px;
            font-size: 10px;
          }

          .level-num {
            display: inline-block;
            width: 20px;
            text-align: center;
            line-height: 24px;
            font-size: 0.675em;
          }

          .level-col {
            position: relative;
            display: inline-block;
            height: 20px;
            line-height: 20px;
            font-size: 0.675em;
            background: none;

            span {
              position: absolute;
              top: 0;
              left: 0;
              line-height: 20x;
              height: 20px;
              margin-left: 20px;
            }

            &.lc-light {
              background: $color-doc-brand-sec;
              flex: 1;
              max-width: 120px;
            }

            &.lc-mid {
              background: darken($color-doc-brand-sec, 15%);
              flex: 0 0 14px;
            }

            &.lc-dark {
              background: darken($color-doc-brand-sec, 25%);
              flex: 0 0 4px;
            }
          }
        }
      }
    }

    .avr-more-link {
      display: block;
      margin: 40px auto 0;
      text-align: center;
      color: $color-dark-grey;
      font-size: 0.925em;

      &:hover {
        text-decoration: underline;
      }

      @media screen and (max-width: 990px) {
        margin: 20px auto 20px;
      }
    }
  }

  .foc-comment-main {
    padding-top: 40px;

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    .foc-comment-ittem {
      position: relative;
      padding-left: 80px;
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        max-width: 400px;
        margin: 40px auto 0;
        padding: 0 20px 0 100px;
      }

      .ci-image-wrap {
        width: 60px;
        height: 60px;
        background-color: grey;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-position: center center;
        background-size: cover;
        background-image: url(../../assets/images/optimized/patient_placeholder.webp);

        @media screen and (max-width: 768px) {
          left: 20px;
        }
      }

      p {
        font-size: 0.875em;
        font-weight: 300;
        line-height: 1.2;
        font-style: italic;
        margin-bottom: 20px;
      }

      .ci-name-rate {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .ci-name {
          font-weight: 600;
          font-size: 1em;
          text-transform: uppercase;
        }

        .ci-rate {
          i {
            color: $color-doc-brand-sec;
            font-size: 16px;
            margin: 0 2px;
          }
        }

        @media screen and (max-width: 480px) {
          flex-direction: column;

          .ci-name,
          .ci-rate {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .home-primary-form {
    width: 100%;
    border-radius: 8px;

    background: #179aad; /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      #179aad 0%,
      #262262 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      #179aad 0%,
      #262262 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      #179aad 0%,
      #262262 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#179aad', endColorstr='#262262',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    .hpf-body {
      display: flex;
      flex-direction: column;
      padding-top: 30px;

      h2 {
        font-weight: 400;
        font-size: 2em;
        color: $color-white;
        text-align: center;
        margin: 0 20px 5px;
      }

      p {
        font-weight: 300;
        font-size: 1.25em;
        line-height: 1.2;
        color: $color-white;
        text-align: center;
        margin: 0 20px 5px;
        font-weight: 300;
      }
    }

    .hpf-inputs {
      padding: 30px 30px 20px;
      margin-top: 20px;

      input {
        border: none;
        outline: none;
        width: 100%;
        height: 45px;
        background: #091725;
        color: $color-white;
        font-size: 0.875em;
        font-weight: 300;
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $color-white;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: $color-white;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: $color-white;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: $color-white;
      }
    }

    .hpf-submit {
      cursor: pointer;
      margin-top: auto;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 50px;
      line-height: 50px;
      background: #091725;
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .home-primary-app-store {
    text-align: right;

    img {
      width: 100%;
      max-width: 140px;
    }
  }

  .press-hero {
    margin-top: 80px;
    width: 100%;
    min-height: 270px;
    background: url(../../assets/images/home-press-hero.jpg);
    background-size: cover;
    background-position: center;

    .home-hero {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      min-height: 270px;

      h1 {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.1;
      }
    }
  }

  .info-hero {
    margin-top: 80px;
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    background: url(../../assets/images/home-invite-hero.jpg);
    background-size: cover;
    background-position: center;

    .home-hero {
      display: flex;
      align-items: flex-end;
      height: 500px;

      h1 {
        text-align: center;
        font-size: 2em;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.1;
      }
    }
  }

  .invite-more-colleagues {
    width: 378px;
    margin: auto;
  }

  .invite-form {
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: space-around;

    .inputs {
      display: flex;
      justify-content: space-between;
      input[name="title"] {
        width: 10%;
      }
      input[name="name"],
      input[name="lastname"] {
        width: 32%;
      }
      input[name="email"] {
        width: 24%;
      }
    }

    input[type="text"] {
      height: 40px;
      line-height: 1;
      font-size: 14px;
      font-weight: 700;
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: $color-doc-brand-sec;
      background: rgba(255, 255, 255, 1);
      border-radius: 2px;

      &::-webkit-input-placeholder {
        color: $color-doc-brand-sec;
        font-weight: 700;
        opacity: 0.5;
      }

      &::-moz-placeholder {
        color: $color-doc-brand-sec;
        font-weight: 700;
        opacity: 0.5;
      }

      &:-ms-input-placeholder {
        color: $color-doc-brand-sec;
        font-weight: 700;
        opacity: 0.5;
      }

      &:-moz-placeholder {
        color: $color-doc-brand-sec;
        font-weight: 700;
        opacity: 0.5;
      }
    }

    .invite-other {
      color: $color-doc-brand;
      margin-bottom: 30px;
      text-align: right;
      cursor: pointer;
    }

    .submit-wrap {
      width: 150px;
      margin: auto;

      a {
        height: 40px;
        line-height: 40px;
        width: 100%;
        padding: 0;
        background: $color-doc-brand;
        border: none;
      }

      label {
        margin-top: 8px;
        width: 100%;
        font-weight: 300;
        color: #fff;
        font-size: 90%;
        text-align: left;
      }
    }
  }

  .info-section,
  .press-section {
    h4 {
      text-align: center;
      font-size: 16px;
      color: $color-doc-brand;
      text-transform: uppercase;
      font-weight: 700;

      &:after {
        content: " ";
        display: block;
        width: 35px;
        height: 1px;
        background: $color-doc-brand;
        margin: 25px auto 0;
      }
    }
  }

  .info-section {
    .row {
      &:first-child {
        border: none;
      }

      p {
        color: $color-dark-grey;
        margin-top: 5px;
        line-height: 1.3;
        font-size: 14px;
      }
    }

    .info-terms {
      .row {
        border: none;
        padding-top: 0;
        ul {
          list-style: initial;
          margin-bottom: 0px;
          color: #878787;
          margin-top: 5px;
          line-height: 1.3;
          font-size: 14px;
        }
        ul.number {
          list-style-type: decimal;
        }
      }

      .question {
        font-weight: 700;
        color: $color-doc-brand-sec;
      }

      .question,
      p {
        padding: 0 20px;
      }
    }
  }

  .info-section,
  .press-table,
  .faq-table {
    .row {
      border-top: 1px solid $color-mid-grey;
      padding-top: 25px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-content: center;

      [class^="col-"],
      [class*=" col-"] {
        align-self: center;
        float: none;
      }
    }

    .subtitle {
      border: none;
    }

    .logo {
      text-align: right;
    }

    p {
      color: $color-doc-brand-sec;
      margin: 0;
    }

    .text-center {
      text-align: center;
    }

    .load-more {
      display: inline-block;
      height: 42px;
      line-height: 42px;
      border: 2px solid #c3cace;
      padding: 0 25px;
      margin: 10px 0;
      text-align: center;
      text-transform: uppercase;
      color: $color-doc-brand-sec;
    }
  }

  .faq-table {
    border-top: 1px solid $color-mid-grey;

    .row {
      padding: 25px 0;
      margin: 0;

      .question {
        color: $color-doc-brand-sec;
        font-weight: 700;
      }
      ul {
        list-style: initial;
        margin-bottom: 0px;
        color: #878787;
        margin-top: 5px;
        line-height: 1.3;
        font-size: 14px;
      }
      ul.letter {
        list-style-type: lower-alpha;
      }
      ul.roman {
        list-style-type: lower-roman;
      }
    }
  }

  .info-media,
  .press-media {
    margin-top: 20px;

    h5 {
      font-size: 24px;
      color: $color-dark-grey;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    a.media-download,
    a.media-view {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 30px;

      img {
        display: block;
        width: 100%;
      }

      .hover-wrap {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(19, 190, 196, 0.75);
        color: #fff;
        font-weight: 300;
        text-align: center;

        span {
          font-size: 17px;
          line-height: 1.3;
        }

        i {
          font-size: 25px;

          &.large {
            font-size: 50px;
          }
        }
      }

      &:hover {
        .hover-wrap {
          display: flex;
        }
      }

      &.media-photo {
        height: 140px;
      }

      &.media-video {
        height: auto;

        img {
          width: 100%;
        }
      }
    }

    .doc-table {
      .row {
        border-bottom: 1px solid $color-mid-grey;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &:last-child {
          border: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      p {
        margin: 0;
        color: $color-doc-brand-sec;
      }
    }

    .press-lightbox {
      z-index: 99999;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);

      .close-lb {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        width: 30px;
        height: 30px;
        color: #fff;
        font-size: 30px;
      }

      .video-wrap {
        width: auto;
      }
    }
  }

  .lndng-section-white,
  .lndng-section-grey,
  .lndng-section-greyblue {
    background: #fff;
    padding: 50px 0;

    &.short-muted {
      padding: 20px 0;
      text-align: center;

      a,
      span {
        display: inline-block;
        color: #888888;
        font-size: 14px;
        font-weight: 400;
      }

      a:hover {
        text-decoration: underline;
      }

      i.div-circ {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        border: 1px solid $color-doc-brand-sec;
        border-radius: 50%;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 60px;
      color: #5a5a5a;
    }

    .sub-title {
      font-size: 38px;
      margin-bottom: 30px;
      letter-spacing: 2px;
    }

    h3 {
      color: #13bec4;
      line-height: 1.6;
      font-size: 36px;
      text-align: center;
      margin-bottom: 60px;
    }

    strong {
      text-transform: uppercase;
    }

    .block-feature__media:after {
      background: #f2f2f2;
    }

    .block-features {
      text-align: center;
      li {
        display: inline-block;
        width: 32%;
        padding: 0;

        a {
          .feature-title {
            color: #13bec4;
          }
        }
      }
    }

    &.lndng-section-grey {
      background: #f2f2f2;
    }

    &.lndng-section-greyblue {
      background: #edf0f2;

      h4 {
        color: $color-darker-grey;

        &:after {
          background: $color-darker-grey;
        }
      }
    }
  }

  .lndng-section-mock {
    padding: 120px 0;
    background-color: #fff;
    background-position: center top, center bottom;
    background-repeat: repeat-x, repeat-x;
    text-align: left;

    img {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }

    h2 {
      font-weight: 400;
      color: $color-font-grey;
      margin-bottom: 40px;
    }

    p {
      font-size: 1em;
      font-weight: 400;
    }

    [class^="btn-"],
    [class*="btn-"] {
      width: 100%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }

    ul {
      padding: 0;
      margin: 0 0 40px;
      text-align: left;

      li {
        list-style: none;
        font-weight: 400;
        margin-bottom: 6px;
      }
    }
  }

  .lndng-section-trusted {
    padding: 70px 0 40px;
    background-color: #fff;
    background-image: url(../../assets/images/section-shadow-top.jpg),
      url(../../assets/images/section-shadow-bottom.jpg);
    background-position: center top, center bottom;
    background-repeat: repeat-x, repeat-x;
    text-align: center;

    h2 {
      margin-bottom: 50px;
      font-size: 1.7;
      font-weight: 600;
      color: $color-font-grey;
    }

    .companies-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: center;

      a {
        display: inline-block;
        max-height: 120px;
        max-width: 160px;
        margin: 0 30px 30px;
        vertical-align: middle;
      }

      img {
      }
    }
  }

  .screenshot-wrap {
    padding: 0 30px 0 0;
    display: flex;
    flex-wrap: wrap;

    .sw-img {
      text-align: center;

      img {
        max-width: 420px;
        width: 100%;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.35);
        border-radius: 4px;
      }
    }

    .sw-text {
      p {
        margin-bottom: 15px;
      }
    }
  }

  .home-icon-list {
    display: block;

    img {
    }
  }

  .services-signup {
    background: url(../../assets/images/home-footer-hero.jpg);
    background-position: center center;
    background-size: cover;

    .gform_heading {
      h3 {
        color: #fff;
      }
    }

    .btn {
      height: 60px;
    }
  }

  .hero--cover {
    max-height: 200px;
    margin-top: 95px;
    background-image: url(../../assets/images/heros/desktop/secondary-hero.webp);
    background-position: center center;
    @media all and (max-width: 480px) {
      background-image: url(../../assets/images/heros/phone/secondary-hero.webp);
    }
    @media all and (min-width: 481px) and (max-width: 768px) {
      background-image: url(../../assets/images/heros/mid/secondary-hero.webp);
    }
    &.faq-hero {
      max-height: 400px;
    }
  }

  .content {
    .entry {
      p {
        line-height: 1.4;
      }
    }
  }

  .layout--flex {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    .layout--flex--cell {
      display: flex;
    }
  }

  .hero__body .flag__image span,
  .team .team-member__block span {
    display: inline-block;
    color: #13bec4;
    font-size: 0.85em;
    text-decoration: underline;
    margin-top: 5px;
    font-weight: 400;
  }

  .hero__body .flag__image span {
    color: #fff;
    margin-top: 10px;
  }

  .hero--team .flag__image img:hover {
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .team .team-member__block:hover img:hover {
    border: 1px solid #13bec4;
    cursor: pointer;
  }

  .layout__team {
    padding-bottom: 0;
    padding-top: 0;
  }

  .billing-container {
    max-width: 800px !important;
  }

  .lndng-pricing {
    max-width: 890px;

    .row {
      display: flex;
      justify-content: space-between;
    }

    .lp-block {
      position: relative;
      width: 100%;
      min-height: 100%;
      padding: 30px 30px 60px;
      margin: 0;
      background: #fff;
      color: #fff;
      text-align: left;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.35);
      border-radius: 4px;

      &.lp-free {
        background: #1393c4;
      }

      &.lp-indiv {
        background: #42548e;
      }

      &.lp-enterprice {
        background: $color-doc-brand;
      }

      h4 {
        color: #fff;
        font-size: 1.4em;
        font-weight: 700;
      }

      .top-desc {
        display: block;
        color: rgba(255, 255, 255, 0.75);
        font-size: 0.7em;
        font-weight: 700;
        margin: -10px 0 15px;
      }

      ul {
        margin-left: 15px;

        li {
          padding: 0.5em 0;
          list-style: circle;
          font-size: 0.9em;
          line-height: 1.3;
        }
      }

      .btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        display: block;
        margin: 0 30px 0;
        outline: 0;
        padding: 1em 1.8em;

        text-align: center;
        font-size: 0.9em;
        font-weight: 700;
        line-height: 1.2;
        border: 0;
        text-decoration: none;
        color: #fff;
        cursor: pointer;

        border-radius: 2px;
        background: -webkit-linear-gradient(top, #61bd4f 0, #5aac44 100%);
        background: linear-gradient(to bottom, #61bd4f 0, #5aac44 100%);
        box-shadow: 0 1px 0 #3f6f21;

        &:hover {
          background: #5aac44;
        }
        &:disabled {
          background: graytext;
          cursor: default;
        }
      }
      .disabled {
        background: graytext;
        cursor: default;
        &:hover {
          background: graytext;
        }
      }
    }
  }

  .lndng-section-blue {
    padding: 30px 0;
    background: $color-doc-brand-sec;
    text-align: center;

    [class^="btn-"],
    [class*="btn-"] {
      min-width: 300px;
    }
  }

  .lndng-section-browse-by {
    width: 100%;
    background: url(../../assets/images/heros/desktop/browse-by-hero.webp);
    background-size: cover;
    background-position: center center;
    background-color: #fff;
    @media all and (min-width: 481px) and (max-width: 768px) {
      background-image: url(../../assets/images/heros/mid/browse-by-hero.webp);
    }
    @media all and (max-width: 480px) {
      background-image: url(../../assets/images/heros/phone/browse-by-hero.webp);
    }
  }

  .lndng-section-pricing {
    width: 100%;
    background: url(../../assets/images/pricing-hero-02.jpg);
    background-size: cover;
    background-position: center center;
    background-color: #fff;
  }

  .lndng-section-online {
    width: 100%;
    background: url(../../assets/images/heros/mid/online-hero.webp);
    background-size: cover;
    background-position: center center;
    background-color: #fff;
    @media all and (min-width: 481px) and (max-width: 768px) {
      background-image: url(../../assets/images/heros/mid/online-hero.webp);
    }
    @media all and (max-width: 480px) {
      background-image: url(../../assets/images/heros/phone/online-hero.webp);
    }
  }

  .lndng-inner-counter {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px;
    color: $color-white;
    background-color: rgba(0, 0, 0, 0.6);

    &.top {
      margin-bottom: 10px;
    }

    &.bottom {
      margin-top: 10px;
    }

    .count-wrap {
      span {
        font-size: 1.1em;
        display: block;
        font-weight: 400;
        text-transform: uppercase;
      }

      em {
        z-index: 2;
        position: relative;
        width: 25px;
        font-family: "Digital-Seven";
        font-size: 3em;
        display: inline-block;
        color: #4affff;
        font-style: normal;
        text-align: right;

        &:before {
          z-index: -1;
          content: attr(data-num);
          position: absolute;
          top: 0;
          right: 0;
          width: 25px;
          text-align: right;
          color: #29332b;
        }
      }
    }
  }

  .lndng-inner-content {
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
    color: $color-white;
    background-color: rgba(0, 0, 0, 0.6);

    h2 {
      color: #fff;
      line-height: 1.4;
      font-size: 2.4em;
      font-weight: 500;
      text-align: center;

      span {
        display: block;
        font-weight: 400;
      }

      b {
        display: block;
        font-weight: 500;

        &.inline {
          display: inline-block;
        }
      }
    }

    .btn-red {
      margin-top: 20px;
      height: 50px;
      font-size: 1.4em;
      font-weight: 500;
      width: 100%;
      max-width: 280px;

      &:hover {
        border-color: darken($color-danger, 7.5%);
        background-color: darken($color-danger, 7.5%);
        color: #fff;
      }
    }

    .quote-wrap {
      text-align: center;
      margin-top: 40px;

      i {
        display: block;
        color: #4affff;
        font-size: 32px;
        margin-bottom: 20px;
      }

      span {
        font-size: 1.25em;
        font-weight: 400;
        line-height: 1.25;
      }
    }
  }

  .lndng-testimonial {
    .row-flex {
      align-items: flex-start;
    }
  }

  .company-testimonial-wrap {
    img {
      display: block;
      max-height: 40px;
      margin: 0 auto 20px;
    }

    p {
      max-width: 300px;
      padding: 0 10px;
      margin: 0 auto;
      text-align: center;
      font-size: 0.9em;
      color: $color-true-black;
      font-weight: 400;
    }
  }

  .slider-trigger {
    color: $color-white;
    font-size: 40px;
    text-align: center;
    display: block;
    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }

    &.dark {
      color: $color-font-grey;

      &:hover {
        color: rgba(0, 0, 0, 0.75);
      }
    }
  }

  .lndng-section-map {
    background-image: url(../../assets/images/ld-map.jpg);
    background-position: center center;
    background-size: cover;
    background-color: $color-map;
    background-repeat: no-repeat;

    .lndng-inner-content {
      text-align: left;
      background: none;
      min-height: 0;
      padding: 80px 0;

      h2 {
        margin-bottom: 40px;
      }

      ul {
        margin: 0;
      }

      li {
        font-size: 1.175em;
        margin-bottom: 8px;
      }
    }
  }
}

/*
  Browse Hospital
*/
.preloader-bh {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: $top-bar-height 20px;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  @media screen and (min-width: 1024px) {
    max-width: 80%;
    margin: auto;
    position: relative;
    background-color: transparent;
  }
  .account-body {
    .prof-load {
      display: flex;
      width: 100%;
      margin: 0 auto 12px;
      justify-content: space-between;
      &.teal {
        .loader-animation-button {
          height: 8px;
          background-color: #13bec4;
          border-radius: 4px;
        }
      }

      .loader-animation {
        height: 8px;
      }

      .left {
        width: 32%;
      }
      .center {
        width: 32%;
      }
      .right {
        width: 32%;
      }
      .half {
        width: 45%;
      }

      .wide {
        width: 100%;
        max-width: 450px;
      }
      .full {
        width: 100%;
      }
    }
  }
}

/*
  Search
*/

.sub--hero {
  background-image: url(../../assets/images/heros/desktop/secondary-hero.webp);
  background-position: center center;
  background-size: cover;
  background-color: #56555b;
  padding-top: 40px;
  padding-bottom: 40px;
  height: auto;
  @media all and (max-width: 480px) {
    background-image: url(../../assets/images/heros/phone/secondary-hero.webp);
  }
  @media all and (min-width: 481px) and (max-width: 768px) {
    background-image: url(../../assets/images/heros/mid/secondary-hero.webp);
  }
  .hero__title {
    text-align: center;
    color: #fff;
    font-size: 2.8em;
    margin-bottom: 20px;
    line-height: 1.6;

    &.bold {
      font-weight: 500;
    }
  }

  .hero__txt {
    color: #fff;
    max-width: 700px;
    text-align: center;
    font-size: 1.25em;
    margin: 10px auto;
  }

  .form-control-lined {
    -webkit-appearance: none !important;
    height: 48px;
    margin: 0;
    padding: 0 20px !important;
    background-color: transparent !important;
    border: 2px solid #c3cace !important;
    border-radius: 0 !important;
    color: $color-white !important;
  }

  ::-webkit-input-placeholder {
    color: $color-white;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $color-white;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: $color-white;
    opacity: 1;
  }
  :-moz-placeholder {
    color: $color-white;
    opacity: 1;
  }

  select.form-control-lined {
    background-image: url("../../assets/images/caret-white-down-lined.png");
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: 20px 12px;
  }

  input.form-control-lined {
    max-width: 280px;
    margin: 0 auto;
  }

  .find-spec-form {
    .dropdown {
      margin-bottom: 10px;
    }
  }

  .form-control-naked {
    max-width: 420px;
    -webkit-appearance: none !important;
    background-color: transparent;
    border: none;
    background-image: url(../../assets/images/caret-white-down-lined.png);
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: 20px 12px;
    color: $color-white;
    font-size: 2.4em;
    padding: 0 40px;
    margin: 10px;
    text-align-last: center;
  }
}

.landing-page .hero--cover--search {
  display: flex;
  min-height: 350px;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center top;
  background-size: cover;
  margin-top: 95px;
  background-image: url(../../assets/images/heros/desktop/search-hero.webp);

  @media all and (min-width: 481px) and (max-width: 768px) {
    background-image: url(../../assets/images/heros/mid/search-hero.webp);
  }
  @media all and (max-width: 480px) {
    background-image: url(../../assets/images/heros/phone/search-hero.webp);
  }
}

.landing-page .hero--cover--imdoctor {
  display: flex;
  min-height: 350px;
  margin-top: 130px;
  padding: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../../assets/images/Docto_ImaDoctor_Hero.jpg);
  background-position: center top;
  background-size: cover;
}

.landing-page .hero--cover.faq-hero {
  display: flex;
  min-height: 450px;
  padding: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../../assets/images/heros/desktop/FAQ.webp);
  background-position: center top;
  background-size: cover;
  @media all and (min-width: 481px) and (max-width: 768px) {
    background-image: url(../../assets/images/heros/mid/FAQ.webp);
  }
  @media all and (max-width: 480px) {
    background-image: url(../../assets/images/heros/phone/FAQ.webp);
  }

  .hero__body {
    max-width: 620px;
    margin: 30px auto 0;
  }

  .hero__title {
    font-size: 40px;
    font-weight: 400;
    color: #fff;
  }
}

.landing-page .find-spec-form {
  text-align: center;
  padding: 0 20px;
  width: 100%;

  p {
    color: $color-white;
    margin: 0 auto;
    text-align: left;
    font-weight: 400;
    padding: 4px 12px;

    &.col-08 {
      width: 66.666%;
    }
  }

  .form-col {
    display: inline-block;
    width: 33.33%;
    margin: 0 -0.25em;
    padding: 0 10px;
    vertical-align: top;
  }

  .btn-search {
    width: 100%;
    background: none;
    border: 1px solid $color-white;
    color: $color-white;
    line-height: 58px;
    padding: 0 25px;
    display: block;
    margin: 0;

    &:hover {
      background: rgba(255, 255, 255, 0.35);
    }
  }
}

.landing-page .consult-doctor-form {
  text-align: center;

  p {
    color: $color-white;
    margin: 0 auto;
    text-align: left;
    font-weight: 400;
    padding: 4px 12px;

    &.col-08 {
      width: 66.666%;
    }
  }

  .form-col {
    display: inline-block;
    width: 29.5%;
    margin: 0 -0.25em;
    padding: 0 10px;
    vertical-align: middle;
  }

  .form-col-center {
    display: inline-block;
    width: 40%;
    margin: 0 -0.25em;
    padding: 0 10px;
    vertical-align: middle;
  }

  .btn-promo {
    width: 100%;
    background: none;
    border: 1px solid $color-white;
    color: $color-white;
    line-height: 58px;
    padding: 0 25px;
    display: block;
    margin: 0;

    &:hover {
      background: rgba(255, 255, 255, 0.35);
    }
  }

  .btn-timer {
    width: 100%;
    background-color: #cc0f0f;
    color: $color-white;
    line-height: 52px;
    display: block;
    margin: 0;
    border-radius: 4px;
    font-size: 19px;

    &:hover {
      background: rgba(204, 24, 18, 0.8);
    }
  }

  .app-store-logo {
    height: 55px;

    &:hover {
    }
  }
}

.find-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .find-block {
    position: relative;
    width: 33.3334%;
    text-align: center;
    padding: 65px 40px 100px;
    background: $color-lighter-grey;

    &:last-child {
      border-left: none;
    }

    &:nth-child(odd) {
      background: $color-white;
    }

    img {
      display: none;
    }

    .doc-profile-img {
      position: relative;
      display: block;
      width: 125px;
      height: 125px;
      margin: 0 auto 20px;
      border-radius: 50%;
      border: 1px solid $color-mid-grey;
      background-position: center top;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.05);
      background-image: url(../../assets/images/profile-undefined.jpg);
    }

    .doc-title,
    .doc-degree {
      display: block;
      margin: 0;
      font-weight: 500;
      text-align: center;
      color: #5d5d5d;
      font-size: 23px;
      text-transform: capitalize;
    }

    .doc-degree {
      font-size: 21px;
      text-transform: uppercase;
    }

    .doc-specialty {
      display: block;
      margin-top: 15px;
      font-weight: 500;
      text-align: center;
      color: $color-doc-brand;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1.3;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 40px;
    }

    .doc-bio {
      font-weight: 400;
      color: $color-font;
      font-size: 14px;
      line-height: 1.35;
      margin-top: 15px;
      text-align: left;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 55px;
    }

    h4 {
      color: $color-darker-grey;
      font-weight: 600;
      font-size: 1.2em;
    }

    .find-block-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 20px 10px 35px;
    }

    .btn-more,
    .btn-book {
      font-weight: 500;
      flex: 0 0 45%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px;
      text-transform: uppercase;
      line-height: 1.4;
      font-size: 1em;
    }

    .btn-more {
      color: $color-doc-brand;
      border: 1px solid $color-doc-brand;
      background-color: transparent;

      &:hover {
        color: $color-white;
        background: $color-doc-brand;
      }
    }

    .btn-book {
      color: $color-white;
      border: 1px solid $color-doc-brand;
      background: $color-doc-brand;

      &:hover {
        color: $color-white;
        background: darken($color-doc-brand, 10%);
      }
    }
  }
}

.refer-wrap,
.im-doctor-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.align-middle {
    align-items: center;
  }

  .im-doctor,
  .refer-col {
    position: relative;
    width: 100%;
    max-width: 500px;
    text-align: center;
    padding: 60px 20px;

    &.top-form {
      padding: 60px;
    }

    &.border-right {
      position: relative;
      border-right: 1px solid $color-doc-brand-sec;

      .add-on {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 40px;
        line-height: 60px;
        margin-right: -20px;
        margin-top: -50px;
        background: #f1f1f1;
        text-align: center;
        font-size: 1.65em;
        color: #13bec4;
        font-weight: 400;
      }
    }

    select {
      width: 100%;
      height: 60px;
      margin-bottom: 15px;
    }

    .ginput_container {
      em {
        display: block;
        margin-top: -8px;
        font-size: 0.9em;
        text-align: left;
      }
    }

    .refer-upload {
      margin: 40px 0 30px;

      .fileselector {
        label {
          max-width: 280px;
          width: 100%;
        }
      }

      .refer-upload-input {
        position: relative;
        width: auto;
        max-width: 240px;
        margin: 0 auto 10px;
        border-radius: 4px;
        background: $color-doc-brand-sec;
        cursor: pointer;

        &:hover {
          background: darken($color-doc-brand-sec, 5%);
        }

        input {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;
          margin: 0;
        }

        span {
          display: flex;
          width: 100%;
          align-content: center;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          padding: 8px 10px;
          text-align: center;
          color: $color-white;

          i {
            margin-right: 5px;
            font-size: 16px;
          }
        }
      }
    }

    .submit-block {
      text-align: right;
      margin-top: 20px;

      .btn {
        width: 100%;

        &:disabled {
          background: graytext;
          border-color: graytext;
          cursor: default;
        }
      }
    }
  }

  .refer-header {
    text-align: left;

    h3 {
      color: $color-doc-brand-sec;
      font-size: 2em;
    }

    p {
      font-size: 1.25em;
    }
    ul {
      margin-left: 2rem;
      list-style-type: square;
    }
    li {
      margin-bottom: 10px;
    }
  }

  .refer-text {
    text-align: left;

    h3 {
      color: $color-doc-brand-sec;
      font-size: 1.75em;
      margin-bottom: 10px;
      text-align: left;
    }

    p {
      font-size: 1em;
    }
  }
}

.im-doctor-wrap {
  .im-doctor {
    max-width: 600px;
    width: auto;
    flex: 1 1 50%;

    .refer-header {
      h3 {
        font-weight: bold;
        color: #888888;
      }

      p {
        font-weight: 400;
      }
    }

    select {
      border: 1px solid #edeae2;
    }
  }

  @media screen and (max-width: 990px) {
    display: block;

    .im-doctor {
      width: 100%;
      max-width: none;
      border: none !important;
    }
  }
}

.ask-question {
  position: relative;
  display: inline-block;
  width: auto;

  .home-label {
    position: absolute;
    right: 100%;
    top: 0;
    width: 210px;
    margin-right: 10px;
    line-height: 46px;
    text-align: right;
  }
}

.column-footer {
  padding: 30px 0 0;
  background: $color-doc-brand;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  h4,
  h2 {
    margin-bottom: 5px;
    margin-top: 10px;
    text-align: left;
    color: $color-white;
    font-weight: 400 !important;

    a {
      text-decoration: none;
      color: $color-white;
      font-weight: 400 !important;

      &:hover {
        color: $color-white;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      margin-bottom: 2px;

      .spacer {
        display: block;
        height: 12px;
      }

      a,
      span {
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
        color: $color-white;
      }

      a:hover {
        color: #f4f4f4;
        text-decoration: underline;
        cursor: pointer;
      }
      button {
        text-decoration: none;
        color: $color-white !important;
        background-color: transparent;
        text-align: left;
        padding-left: 0px;
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
        color: #fff;

        &:hover {
          color: $color-white;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .highlight-link {
        color: #d43545;

        &:hover {
          color: #d43545;
        }
      }

      [class^="icon-"],
      [class*=" icon-"] {
        display: inline-block;
        font-size: 13px;
        margin-right: 5px;
        text-decoration: none;
      }

      .img-icon {
        margin-right: 4px;

        img {
          width: 14px;
        }
      }
    }
  }

  .copyright {
    background: rgba(0, 0, 0, 0.47);
    padding: 15px;
    margin: 20px 0 0;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
  }

  .footer-app-store {
    margin-top: 30px;
    padding: 15px 0;
    color: $color-white;
    text-align: center;

    a {
      display: inline-block;
      img {
        width: 200px;
      }
    }
  }
}
