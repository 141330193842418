.switch-container {
}

.switch-wrap {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  .icon-info {
    //position: absolute;
    //top: 0;
    //right: 35px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
    color: $color-doc-brand-sec;
    vertical-align: sub;
    font-size: 1.35em;
  }

  span {
    display: block;
    line-height: 1.4;
    font-size: 12px;
    font-weight: 600;
    width: 82px;

    &:first-child {
      text-align: right;
    }

    &:last-child {
      text-align: left;
    }
  }

  label {
    margin: 0;

    input {
      display: none !important;
    }

    .switch-it {
      position: relative;
      width: 55px;
      height: 28px;
      border-radius: 20px;
      background: $color-dark-grey;
      border: 1px solid $color-border-grey;
      margin: 0 10px;
      cursor: pointer;

      i {
        position: absolute;
        top: 1px;
        left: 1px;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 14px;
        background: #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      }
    }

    input:checked + .switch-it {
      background-color: $color-connect-green;

      i {
        left: initial;
        right: 1px;
      }
    }
  }
}
